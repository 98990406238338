import * as Api from '../../api';

export const getLeadersScore = ({
  type,
  startDatetime,
  endDatetime,
  groupId,
  offset,
  limit,
} = {}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.getLeadersScore({
      appAuthToken,
      type,
      startDatetime,
      endDatetime,
      groupId,
      offset,
      limit,
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getLeadersAccuracy = ({
  type,
  startDatetime,
  endDatetime,
  groupId,
  offset,
  limit,
} = {}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.getLeadersAccuracy({
      appAuthToken,
      type,
      startDatetime,
      endDatetime,
      groupId,
      offset,
      limit,
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getLeadersWordsLearned = ({
  type,
  startDatetime,
  endDatetime,
  groupId,
  offset,
  limit,
} = {}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.getLeadersWordsLearned({
      appAuthToken,
      type,
      startDatetime,
      endDatetime,
      groupId,
      offset,
      limit,
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getLeadersWordsRemembered = ({
  type,
  startDatetime,
  endDatetime,
  groupId,
  offset,
  limit,
} = {}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.getLeadersWordsRemembered({
      appAuthToken,
      type,
      startDatetime,
      endDatetime,
      groupId,
      offset,
      limit,
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
