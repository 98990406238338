import {
  APP_INITIALIZED,
  LOGIN,
  LOGIN_SUCCEED,
  LOGIN_FAILED,
  LOGOUT,
  LOGOUT_SUCCEED,
  LOGOUT_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCEED,
  CHANGE_PASSWORD_FAILED,
  RESET_CHANGE_PASSWORD_STATUS,
} from '../actions/types';

export const initialized = (state = false, action) => {
  switch (action.type) {
    case APP_INITIALIZED:
      return true;
    default:
      return state;
  }
};

export const appAuthToken = (state = null, action) => {
  switch (action.type) {
    case LOGIN_SUCCEED:
      return action.appAuthToken;
    case LOGOUT_SUCCEED:
      return null;
    default:
      return state;
  }
};

export const loginStatus = (
  state = {
    name: 'NOT_LOGGED_IN',
  },
  action
) => {
  switch (action.type) {
    case LOGIN:
      return {
        name: 'LOGGING_IN',
      };
    case LOGIN_SUCCEED:
      return {
        name: 'LOGGED_IN',
      };
    case LOGIN_FAILED:
      return {
        name: LOGIN_FAILED,
        error: action.error,
      };
    case LOGOUT:
      return {
        name: 'LOGGING_OUT',
      };
    case LOGOUT_FAILED:
      return {
        name: LOGOUT_FAILED,
      };
    case LOGOUT_SUCCEED:
      return {
        name: 'NOT_LOGGED_IN',
      };
    default:
      return state;
  }
};

export const changePasswordStatus = (
  state = {
    name: '',
  },
  action
) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return {
        name: 'CHANGING_PASSWORD',
      };
    case CHANGE_PASSWORD_SUCCEED:
      return {
        name: 'PASSWORD_CHANGED',
      };
    case CHANGE_PASSWORD_FAILED:
      return {
        name: CHANGE_PASSWORD_FAILED,
        error: action.error,
      };
    case RESET_CHANGE_PASSWORD_STATUS:
      return {
        name: '',
      };
    default:
      return state;
  }
};
