import queryString from 'query-string';

import { httpGet, httpPost, httpPut, httpDelete } from './common';

import { API_BASE_URL } from '../constants';

export const requestSrpChallenge = ({ username, clientPublicEphemeral }) => {
  return httpPost(`${API_BASE_URL}/auth/basic/srp_challenge`, null, {
    username,
    clientPublicEphemeral,
  });
};

export const loginSrp = ({ username, referenceId, clientSessionProof }) => {
  return httpPost(`${API_BASE_URL}/auth/basic/token`, null, {
    username,
    referenceId,
    clientSessionProof,
  });
};

export const verifyTokenAndGetAdminUser = ({ appAuthToken }) => {
  return httpGet(`${API_BASE_URL}/auth/verifyToken`, {
    'x-access-token': appAuthToken,
  });
};

export const changeCredential = ({
  appAuthToken,
  username,
  referenceId,
  clientSessionProof,
  newCredential,
}) => {
  return httpPut(
    `${API_BASE_URL}/auth/basic/credential`,
    {
      'x-access-token': appAuthToken,
    },
    {
      username,
      referenceId,
      clientSessionProof,
      newCredential,
    }
  );
};

export const getManagementUsers = ({
  appAuthToken,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/management-users?${queryString.stringify({
      offset,
      limit,
      sort,
      sortOrder,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getManagementUser = ({ appAuthToken, id }) => {
  return httpGet(`${API_BASE_URL}/management-users/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export const createManagementUser = ({
  appAuthToken,
  username,
  verifier,
  salt,
  iterations,
  email,
  displayName,
  profilePictureUrl,
  role,
}) => {
  return httpPost(
    `${API_BASE_URL}/management-users`,
    {
      'x-access-token': appAuthToken,
    },
    {
      username,
      verifier,
      salt,
      iterations,
      email,
      displayName,
      profilePictureUrl,
      role,
    }
  );
};

export const updateManagementUser = ({
  appAuthToken,
  id,
  username,
  role,
  email,
  displayName,
  profilePictureUrl,
}) => {
  return httpPut(
    `${API_BASE_URL}/management-users/${id}`,
    {
      'x-access-token': appAuthToken,
    },
    {
      username,
      role,
      email,
      displayName,
      profilePictureUrl,
    }
  );
};

export const setActiveManagementUser = ({ appAuthToken, id, active }) => {
  return httpPut(
    `${API_BASE_URL}/management-users/${id}/active`,
    {
      'x-access-token': appAuthToken,
    },
    {
      active,
    }
  );
};

export const resetManagementUserCredential = ({
  appAuthToken,
  id,
  newCredential,
}) => {
  return httpPut(
    `${API_BASE_URL}/management-users/${id}/credentials`,
    {
      'x-access-token': appAuthToken,
    },
    {
      newCredential,
    }
  );
};

export const removeManagementUser = ({ appAuthToken, id }) => {
  return httpDelete(`${API_BASE_URL}/management-users/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export const getUsers = ({
  appAuthToken,
  search,
  groupId,
  targetAudienceId,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  let queriesStr = queryString.stringify({
    search,
    groupId,
    targetAudienceId,
    offset,
    limit,
    sort,
    sortOrder,
  });
  queriesStr = queriesStr ? '?' + queriesStr : '';
  return httpGet(`${API_BASE_URL}/users${queriesStr}`, {
    'x-access-token': appAuthToken,
  });
};

export const getUser = ({ appAuthToken, id }) => {
  return httpGet(`${API_BASE_URL}/users/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export const resetUser = ({ appAuthToken, id }) => {
  return httpDelete(`${API_BASE_URL}/users/${id}/data`, {
    'x-access-token': appAuthToken,
  });
};

export const deleteUserAccountData = ({ appAuthToken, userId }) => {
  return httpPost(
    `${API_BASE_URL}/delete_account_data`,
    {
      'x-access-token': appAuthToken,
    },
    {
      userId,
    }
  );
};

export const getUserStats = ({ appAuthToken, userId }) => {
  return httpGet(`${API_BASE_URL}/users/${userId}/stats`, {
    'x-access-token': appAuthToken,
  });
};

export const getUserLearnHistory = async ({
  appAuthToken,
  userId,
  periodType,
  periodCount,
}) => {
  let queriesStr = queryString.stringify({
    periodType,
    periodCount,
  });
  queriesStr = queriesStr ? '?' + queriesStr : '';
  return httpGet(`${API_BASE_URL}/users/${userId}/learnHistory${queriesStr}`, {
    'x-access-token': appAuthToken,
  });
};

export const getUserDetailedLearnHistory = async ({
  appAuthToken,
  userId,
  offset,
  limit,
}) => {
  let queriesStr = queryString.stringify({
    offset,
    limit,
  });
  queriesStr = queriesStr ? '?' + queriesStr : '';
  return httpGet(
    `${API_BASE_URL}/users/${userId}/detailedLearnHistory${queriesStr}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getUserAccuracyHistory = async ({
  appAuthToken,
  userId,
  periodType,
  periodCount,
}) => {
  let queriesStr = queryString.stringify({
    periodType,
    periodCount,
  });
  queriesStr = queriesStr ? '?' + queriesStr : '';
  return httpGet(
    `${API_BASE_URL}/users/${userId}/accuracyHistory${queriesStr}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getUserScoreHistory = async ({
  appAuthToken,
  userId,
  periodType,
  periodCount,
}) => {
  let queriesStr = queryString.stringify({
    periodType,
    periodCount,
  });
  queriesStr = queriesStr ? '?' + queriesStr : '';
  return httpGet(`${API_BASE_URL}/users/${userId}/scoreHistory${queriesStr}`, {
    'x-access-token': appAuthToken,
  });
};

export const getGroups = ({
  appAuthToken,
  search,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/groups?${queryString.stringify({
      search,
      offset,
      limit,
      sort,
      sortOrder,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getGroup = ({ appAuthToken, groupId }) => {
  return httpGet(`${API_BASE_URL}/groups/${groupId}`, {
    'x-access-token': appAuthToken,
  });
};

export const createGroup = ({ appAuthToken, name, type, pictureUrl }) => {
  return httpPost(
    `${API_BASE_URL}/groups`,
    {
      'x-access-token': appAuthToken,
    },
    {
      name,
      type,
      pictureUrl,
    }
  );
};

export const updateGroup = ({
  appAuthToken,
  groupId,
  name,
  pictureUrl,
  active,
}) => {
  return httpPut(
    `${API_BASE_URL}/groups/${groupId}`,
    {
      'x-access-token': appAuthToken,
    },
    {
      name,
      pictureUrl,
      active,
    }
  );
};

export const removeGroup = ({ appAuthToken, groupId }) => {
  return httpDelete(`${API_BASE_URL}/groups/${groupId}`, {
    'x-access-token': appAuthToken,
  });
};

export const getUsersByGroup = ({
  appAuthToken,
  groupId,
  search,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/groups/${groupId}/users?${queryString.stringify({
      search,
      offset,
      limit,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const addGroupPermissionToManagementUser = ({
  appAuthToken,
  managementUserId,
  groupIds,
}) => {
  return httpPost(
    `${API_BASE_URL}/management-users/${managementUserId}/groups`,
    {
      'x-access-token': appAuthToken,
    },
    {
      groupIds,
    }
  );
};

export const removeGroupPermissionFromManagementUser = ({
  appAuthToken,
  managementUserId,
  groupId,
}) => {
  return httpDelete(
    `${API_BASE_URL}/management-users/${managementUserId}/groups/${groupId}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const addUsersToGroup = ({ appAuthToken, groupId, userIds }) => {
  return httpPost(
    `${API_BASE_URL}/groups/${groupId}/users`,
    {
      'x-access-token': appAuthToken,
    },
    {
      userIds,
    }
  );
};

export const removeUserFromGroup = ({ appAuthToken, groupId, userId }) => {
  return httpDelete(`${API_BASE_URL}/groups/${groupId}/users/${userId}`, {
    'x-access-token': appAuthToken,
  });
};

export const getStatsSummary = ({ appAuthToken }) => {
  return httpGet(`${API_BASE_URL}/stats/summary`, {
    'x-access-token': appAuthToken,
  });
};

export const getRecentNewUsersHistory = ({
  appAuthToken,
  periodType,
  periodCount,
  groupId,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/recentNewUsers?${queryString.stringify({
      periodType,
      periodCount,
      groupId,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getRecentWordsLearnedHistory = ({
  appAuthToken,
  periodType,
  periodCount,
  contentId,
  groupId,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/recentWordsLearned?${queryString.stringify({
      periodType,
      periodCount,
      contentId,
      groupId,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getRecentWordsLearnedUserCountHistory = ({
  appAuthToken,
  periodType,
  periodCount,
  contentId,
  groupId,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/recentWordsLearnedUserCount?${queryString.stringify({
      periodType,
      periodCount,
      contentId,
      groupId,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getRecentWordsRememberedHistory = ({
  appAuthToken,
  periodType,
  periodCount,
  contentId,
  groupId,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/recentWordsRemembered?${queryString.stringify({
      periodType,
      periodCount,
      contentId,
      groupId,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getRecentWordsRememberedUserCountHistory = ({
  appAuthToken,
  periodType,
  periodCount,
  contentId,
  groupId,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/recentWordsRememberedUserCount?${queryString.stringify(
      {
        periodType,
        periodCount,
        contentId,
        groupId,
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getLeadersScore = ({
  appAuthToken,
  type,
  startDatetime,
  endDatetime,
  groupId,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/leader/score?${queryString.stringify({
      type,
      startDatetime,
      endDatetime,
      groupId,
      offset,
      limit,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getLeadersAccuracy = ({
  appAuthToken,
  type,
  startDatetime,
  endDatetime,
  groupId,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/leader/accuracy?${queryString.stringify({
      type,
      startDatetime,
      endDatetime,
      groupId,
      offset,
      limit,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getLeadersWordsLearned = ({
  appAuthToken,
  type,
  startDatetime,
  endDatetime,
  groupId,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/leader/wordsLearned?${queryString.stringify({
      type,
      startDatetime,
      endDatetime,
      groupId,
      offset,
      limit,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getLeadersWordsRemembered = ({
  appAuthToken,
  type,
  startDatetime,
  endDatetime,
  groupId,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/leader/wordsRemembered?${queryString.stringify({
      type,
      startDatetime,
      endDatetime,
      groupId,
      offset,
      limit,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getRecentWordsLearnedHistoryByUsers = ({
  appAuthToken,
  periodType,
  periodCount,
  userIds,
}) => {
  return httpPost(
    `${API_BASE_URL}/stats/recentWordsLearnedHistoryByUsers`,
    {
      'x-access-token': appAuthToken,
    },
    {
      periodType,
      periodCount,
      userIds,
    }
  );
};

export const getRecentWordsRememberedHistoryByUsers = ({
  appAuthToken,
  periodType,
  periodCount,
  userIds,
}) => {
  return httpPost(
    `${API_BASE_URL}/stats/recentWordsRememberedHistoryByUsers`,
    {
      'x-access-token': appAuthToken,
    },
    {
      periodType,
      periodCount,
      userIds,
    }
  );
};

export const getRecentQuizTakenFirstTimeHistoryByUsers = ({
  appAuthToken,
  periodType,
  periodCount,
  userIds,
}) => {
  return httpPost(
    `${API_BASE_URL}/stats/recentQuizTakenFirstTimeHistoryByUsers`,
    {
      'x-access-token': appAuthToken,
    },
    {
      periodType,
      periodCount,
      userIds,
    }
  );
};

export const getRecentQuizTakenHistoryByUsers = ({
  appAuthToken,
  periodType,
  periodCount,
  userIds,
}) => {
  return httpPost(
    `${API_BASE_URL}/stats/recentQuizTakenHistoryByUsers`,
    {
      'x-access-token': appAuthToken,
    },
    {
      periodType,
      periodCount,
      userIds,
    }
  );
};

export const getContentsWordsLearned = ({ appAuthToken, contentIds }) => {
  return httpPost(
    `${API_BASE_URL}/stats/contentsWordsLearned`,
    {
      'x-access-token': appAuthToken,
    },
    {
      contentIds,
    }
  );
};

export const getContentsQuizzesTaken = ({ appAuthToken, contentIds }) => {
  return httpPost(
    `${API_BASE_URL}/stats/contentsQuizzesTaken`,
    {
      'x-access-token': appAuthToken,
    },
    {
      contentIds,
    }
  );
};

export const getContentsLearnedUsers = ({ appAuthToken, contentIds }) => {
  return httpPost(
    `${API_BASE_URL}/stats/contentsLearnedUsers`,
    {
      'x-access-token': appAuthToken,
    },
    {
      contentIds,
    }
  );
};

export const getContentsQuizzesTakenUsers = ({ appAuthToken, contentIds }) => {
  return httpPost(
    `${API_BASE_URL}/stats/contentsQuizzesTakenUsers`,
    {
      'x-access-token': appAuthToken,
    },
    {
      contentIds,
    }
  );
};

export const getRecentWordsLearnedHistoryByContents = ({
  appAuthToken,
  periodType,
  periodCount,
  contentIds,
}) => {
  return httpPost(
    `${API_BASE_URL}/stats/recentWordsLearnedHistoryByContents`,
    {
      'x-access-token': appAuthToken,
    },
    {
      periodType,
      periodCount,
      contentIds,
    }
  );
};

export const getRecentQuizzesTakenHistoryByContents = ({
  appAuthToken,
  periodType,
  periodCount,
  contentIds,
}) => {
  return httpPost(
    `${API_BASE_URL}/stats/recentQuizzesTakenHistoryByContents`,
    {
      'x-access-token': appAuthToken,
    },
    {
      periodType,
      periodCount,
      contentIds,
    }
  );
};

export const getRecentLearnedUsersHistoryByContents = ({
  appAuthToken,
  periodType,
  periodCount,
  contentIds,
}) => {
  return httpPost(
    `${API_BASE_URL}/stats/recentLearnedUsersHistoryByContents`,
    {
      'x-access-token': appAuthToken,
    },
    {
      periodType,
      periodCount,
      contentIds,
    }
  );
};

export const getRecentQuizzesTakenUsersHistoryByContents = ({
  appAuthToken,
  periodType,
  periodCount,
  contentIds,
}) => {
  return httpPost(
    `${API_BASE_URL}/stats/recentQuizzesTakenUsersHistoryByContents`,
    {
      'x-access-token': appAuthToken,
    },
    {
      periodType,
      periodCount,
      contentIds,
    }
  );
};
