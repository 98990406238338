import queryString from 'query-string';

import { httpGet, httpGetDownloadFile, httpPost } from './common';

import { API_BASE_URL } from '../constants';

export const getLearnGroupWordsLearnedUsers = ({
  appAuthToken,
  learnGroupId,
  from,
  to,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/learnGroupWordsLearnedUsers?${queryString.stringify(
      {
        learnGroupId,
        from,
        to,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getLearnGroupUserWordsLearned = ({
  appAuthToken,
  learnGroupId,
  userId,
  from,
  to,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/learnGroupUserWordsLearned?${queryString.stringify(
      {
        learnGroupId,
        userId,
        from,
        to,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getLearnGroupQuizResultsUsers = ({
  appAuthToken,
  learnGroupId,
  from,
  to,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/learnGroupQuizResultsUsers?${queryString.stringify(
      {
        learnGroupId,
        from,
        to,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getLearnGroupUsersQuizResults = ({
  appAuthToken,
  learnGroupId,
  userId,
  from,
  to,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/learnGroupUsersQuizResults?${queryString.stringify(
      {
        learnGroupId,
        userId,
        from,
        to,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getLearnGroupUsersQuizAnswersFileExport = ({
  appAuthToken,
  fileType,
  learnGroupId,
  userId,
  from,
  to,
}) => {
  return httpGetDownloadFile(
    `${API_BASE_URL}/stats/learnGroupUsersQuizAnswersFileExport?${queryString.stringify(
      {
        fileType,
        learnGroupId,
        userId,
        from,
        to,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getLearnGroupQuizQuestionQuizResultsUsers = ({
  appAuthToken,
  learnGroupId,
  from,
  to,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/learnGroupQuizQuestionQuizResultsUsers?${queryString.stringify(
      {
        learnGroupId,
        from,
        to,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getLearnGroupUsersQuizQuestionQuizResults = ({
  appAuthToken,
  learnGroupId,
  userId,
  from,
  to,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/learnGroupUsersQuizQuestionQuizResults?${queryString.stringify(
      {
        learnGroupId,
        userId,
        from,
        to,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getLearnGroupUsersQuizQuestionQuizAnswersFileExport = ({
  appAuthToken,
  fileType,
  learnGroupId,
  userId,
  from,
  to,
}) => {
  return httpGetDownloadFile(
    `${API_BASE_URL}/stats/learnGroupUsersQuizQuestionQuizAnswersFileExport?${queryString.stringify(
      {
        fileType,
        learnGroupId,
        userId,
        from,
        to,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};
