import { combineReducers } from 'redux';

import * as appReducer from './app';
import * as userReducer from './user';

const rootReducer = combineReducers({
  ...appReducer,
  ...userReducer,
});

export default rootReducer;
