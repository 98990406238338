import * as CmsScheduleApi from '../../api/cms_schedule';

export const getLearnGroupsByPublishDate =
  ({ publishStartDate, publishEndDate, sortOrder }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsScheduleApi.getLearnGroupsByPublishDate({
        appAuthToken,
        publishStartDate,
        publishEndDate,
        sortOrder,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get learn groups by publish date');
    }
  };

export const getScheduledPublishLearnGroupsByPublishDate =
  ({ publishStartDate, publishEndDate, sortOrder }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response =
        await CmsScheduleApi.getScheduledPublishLearnGroupsByPublishDate({
          appAuthToken,
          publishStartDate,
          publishEndDate,
          sortOrder,
        });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(
        'Cannot get scheduled publish learn groups by publish date'
      );
    }
  };

export const getContentsByContentUpdatedDate =
  ({ startDate, endDate, targetTopicId, targetAudienceId, sortOrder }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsScheduleApi.getContentsByContentUpdatedDate({
        appAuthToken,
        startDate,
        endDate,
        targetTopicId,
        targetAudienceId,
        sortOrder,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get contents by content updated date');
    }
  };

export const getScheduledSetContentUpdatedContentsByContentUpdatedDate =
  ({ startDate, endDate, targetTopicId, targetAudienceId, sortOrder }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response =
        await CmsScheduleApi.getScheduledSetContentUpdatedContentsByContentUpdatedDate(
          {
            appAuthToken,
            startDate,
            endDate,
            targetTopicId,
            targetAudienceId,
            sortOrder,
          }
        );
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(
        'Cannot get scheduled set content updated contents by content updated date'
      );
    }
  };
