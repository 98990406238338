import * as TitleStatsApi from '../../api/titleStats';

export const getContentsSortableWordsLearned =
  ({
    search,
    categoryIds,
    targetTopicId,
    targetAudienceId,
    order,
    offset,
    limit,
  }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await TitleStatsApi.getContentsSortableWordsLearned({
        appAuthToken,
        search,
        categoryIds,
        targetTopicId,
        targetAudienceId,
        order,
        offset,
        limit,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get contents with sortable words learned';
    }
  };

export const getContentsSortableQuizzesTaken =
  ({
    search,
    categoryIds,
    targetTopicId,
    targetAudienceId,
    order,
    offset,
    limit,
  }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await TitleStatsApi.getContentsSortableQuizzesTaken({
        appAuthToken,
        search,
        categoryIds,
        targetTopicId,
        targetAudienceId,
        order,
        offset,
        limit,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get contents with sortable quizzes taken');
    }
  };

export const getContentsSortableLearnedUsers =
  ({
    search,
    categoryIds,
    targetTopicId,
    targetAudienceId,
    order,
    offset,
    limit,
  }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await TitleStatsApi.getContentsSortableLearnedUsers({
        appAuthToken,
        search,
        categoryIds,
        targetTopicId,
        targetAudienceId,
        order,
        offset,
        limit,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get contents with sortable learned users';
    }
  };

export const getContentsSortableQuizzesTakenUsers =
  ({
    search,
    categoryIds,
    targetTopicId,
    targetAudienceId,
    order,
    offset,
    limit,
  }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await TitleStatsApi.getContentsSortableQuizzesTakenUsers(
        {
          appAuthToken,
          search,
          categoryIds,
          targetTopicId,
          targetAudienceId,
          order,
          offset,
          limit,
        }
      );
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get contents with sortable quizzes taken users');
    }
  };

export const getContentsSortableWordsLearnedInPeriod =
  ({
    datetime,
    periodType,
    search,
    categoryIds,
    targetTopicId,
    targetAudienceId,
    order,
    offset,
    limit,
  }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response =
        await TitleStatsApi.getContentsSortableWordsLearnedInPeriod({
          appAuthToken,
          datetime,
          periodType,
          search,
          categoryIds,
          targetTopicId,
          targetAudienceId,
          order,
          offset,
          limit,
        });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get contents with sortable words learned in period';
    }
  };

export const getContentsSortableQuizzesTakenInPeriod =
  ({
    datetime,
    periodType,
    search,
    categoryIds,
    targetTopicId,
    targetAudienceId,
    order,
    offset,
    limit,
  }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response =
        await TitleStatsApi.getContentsSortableQuizzesTakenInPeriod({
          appAuthToken,
          datetime,
          periodType,
          search,
          categoryIds,
          targetTopicId,
          targetAudienceId,
          order,
          offset,
          limit,
        });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(
        'Cannot get contents with sortable quizzes taken in period'
      );
    }
  };

export const getContentsSortableLearnedUsersInPeriod =
  ({
    datetime,
    periodType,
    search,
    categoryIds,
    targetTopicId,
    targetAudienceId,
    order,
    offset,
    limit,
  }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response =
        await TitleStatsApi.getContentsSortableLearnedUsersInPeriod({
          appAuthToken,
          datetime,
          periodType,
          search,
          categoryIds,
          targetTopicId,
          targetAudienceId,
          order,
          offset,
          limit,
        });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get contents with sortable learned users in period';
    }
  };

export const getContentsSortableQuizzesTakenUsersInPeriod =
  ({
    datetime,
    periodType,
    search,
    categoryIds,
    targetTopicId,
    targetAudienceId,
    order,
    offset,
    limit,
  }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response =
        await TitleStatsApi.getContentsSortableQuizzesTakenUsersInPeriod({
          appAuthToken,
          datetime,
          periodType,
          search,
          categoryIds,
          targetTopicId,
          targetAudienceId,
          order,
          offset,
          limit,
        });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(
        'Cannot get contents with sortable quizzes taken users in period'
      );
    }
  };
