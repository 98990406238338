import queryString from 'query-string';

import { httpGet, httpPost, httpPut, httpDelete } from './common';

import { API_BASE_URL } from '../constants';

export const listDiscoverScreenSettings = ({
  appAuthToken,
  targetAudienceId,
  targetTopicId,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/discover_screen_settings?${queryString.stringify({
      targetAudienceId,
      targetTopicId,
      offset,
      limit,
      sort,
      sortOrder,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getDiscoverScreenSettings = ({
  appAuthToken,
  discoverScreenId,
}) => {
  return httpGet(
    `${API_BASE_URL}/discover_screen_settings/${discoverScreenId}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const createDiscoverScreenSettings = ({
  appAuthToken,
  featuredTitles,
  categories,
  active,
  targetAudienceId,
  targetTopicId,
}) => {
  return httpPost(
    `${API_BASE_URL}/discover_screen_settings`,
    {
      'x-access-token': appAuthToken,
    },
    {
      featuredTitles,
      categories,
      active,
      targetAudienceId,
      targetTopicId,
    }
  );
};

export const updateDiscoverScreenSettings = ({
  appAuthToken,
  discoverScreenId,
  featuredTitles,
  categories,
  order,
  active,
}) => {
  return httpPut(
    `${API_BASE_URL}/discover_screen_settings/${discoverScreenId}`,
    {
      'x-access-token': appAuthToken,
    },
    {
      featuredTitles,
      categories,
      order,
      active,
    }
  );
};

export const removeDiscoverScreenSettings = ({
  appAuthToken,
  discoverScreenId,
}) => {
  return httpDelete(
    `${API_BASE_URL}/discover_screen_settings/${discoverScreenId}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};
