import queryString from 'query-string';

import { httpGet, httpPost, httpPut, httpDelete, upload } from './common';

import { API_BASE_URL } from '../constants';

export const getCourseMains = ({
  appAuthToken,
  groupId,
  search,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/course_mains?${queryString.stringify(
      {
        groupId,
        search,
        offset,
        limit,
        sort,
        sortOrder,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getCourseMain = ({ appAuthToken, id }) => {
  return httpGet(`${API_BASE_URL}/course_mains/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export const createCourseMain = ({ appAuthToken, formData, onProgress }) => {
  return upload({
    method: 'POST',
    url: `${API_BASE_URL}/course_mains`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export const updateCourseMain = ({
  appAuthToken,
  id,
  formData,
  onProgress,
}) => {
  return upload({
    method: 'PUT',
    url: `${API_BASE_URL}/course_mains/${id}`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export function removeCourseMain({ appAuthToken, id }) {
  return httpDelete(`${API_BASE_URL}/course_mains/${id}`, {
    'x-access-token': appAuthToken,
  });
}

export const getCourseTopics = ({
  appAuthToken,
  courseMainId,
  search,
  targetAudienceId,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/course_topics?${queryString.stringify(
      {
        courseMainId,
        search,
        targetAudienceId,
        offset,
        limit,
        sort,
        sortOrder,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getCourseTopic = ({ appAuthToken, id }) => {
  return httpGet(`${API_BASE_URL}/course_topics/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export function createCourseTopic({ appAuthToken, formData, onProgress }) {
  return upload({
    method: 'POST',
    url: `${API_BASE_URL}/course_topics`,
    appAuthToken,
    formData,
    onProgress,
  });
}

export function updateCourseTopic({ appAuthToken, id, formData, onProgress }) {
  return upload({
    method: 'PUT',
    url: `${API_BASE_URL}/course_topics/${id}`,
    appAuthToken,
    formData,
    onProgress,
  });
}

export function removeCourseTopic({ appAuthToken, id }) {
  return httpDelete(`${API_BASE_URL}/course_topics/${id}`, {
    'x-access-token': appAuthToken,
  });
}

export const getCourseSubtopics = ({
  appAuthToken,
  courseTopicId,
  search,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/course_subtopics?${queryString.stringify(
      {
        courseTopicId,
        search,
        offset,
        limit,
        sort,
        sortOrder,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getCourseSubtopic = ({ appAuthToken, id }) => {
  return httpGet(`${API_BASE_URL}/course_subtopics/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export function createCourseSubtopic({
  appAuthToken,
  name,
  nameByLang,
  description,
  active,
  order,
  courseTopicId,
}) {
  return httpPost(
    `${API_BASE_URL}/course_subtopics`,
    {
      'x-access-token': appAuthToken,
    },
    { name, nameByLang, description, active, order, courseTopicId }
  );
}

export function updateCourseSubtopic({
  appAuthToken,
  id,
  name,
  nameByLang,
  description,
  active,
  order,
  courseTopicId,
}) {
  return httpPut(
    `${API_BASE_URL}/course_subtopics/${id}`,
    {
      'x-access-token': appAuthToken,
    },
    { name, nameByLang, description, active, order, courseTopicId }
  );
}

export function removeCourseSubtopic({ appAuthToken, id }) {
  return httpDelete(`${API_BASE_URL}/course_subtopics/${id}`, {
    'x-access-token': appAuthToken,
  });
}

export const getCourses = ({
  appAuthToken,
  courseSubtopicId,
  search,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/courses?${queryString.stringify(
      {
        courseSubtopicId,
        search,
        offset,
        limit,
        sort,
        sortOrder,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getCourse = ({ appAuthToken, id }) => {
  return httpGet(`${API_BASE_URL}/courses/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export const createCourse = ({ appAuthToken, formData, onProgress }) => {
  return upload({
    method: 'POST',
    url: `${API_BASE_URL}/courses`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export const updateCourse = ({ appAuthToken, id, formData, onProgress }) => {
  return upload({
    method: 'PUT',
    url: `${API_BASE_URL}/courses/${id}`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export function removeCourse({ appAuthToken, id }) {
  return httpDelete(`${API_BASE_URL}/courses/${id}`, {
    'x-access-token': appAuthToken,
  });
}

export function addContentToCourse({
  appAuthToken,
  courseId,
  contentId,
  order,
}) {
  return httpPost(
    `${API_BASE_URL}/courses/${courseId}/contents`,
    {
      'x-access-token': appAuthToken,
    },
    { contentId, order }
  );
}

export function updateCourseContent({
  appAuthToken,
  courseId,
  contentId,
  order,
}) {
  return httpPut(
    `${API_BASE_URL}/courses/${courseId}/contents/${contentId}`,
    {
      'x-access-token': appAuthToken,
    },
    { order }
  );
}

export function removeContentFromCourse({ appAuthToken, courseId, contentId }) {
  return httpDelete(
    `${API_BASE_URL}/courses/${courseId}/contents/${contentId}`,
    {
      'x-access-token': appAuthToken,
    }
  );
}
