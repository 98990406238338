import queryString from 'query-string';

import { httpGet, httpPost, httpPut, httpDelete } from './common';

import { API_BASE_URL } from '../constants';

export const getContentTargets = ({ appAuthToken, contentId }) => {
  return httpGet(
    `${API_BASE_URL}/content_targets?${queryString.stringify({
      contentId,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const addContentTarget = ({
  appAuthToken,
  contentId,
  targetAudienceId,
  targetTopicId,
}) => {
  return httpPost(
    `${API_BASE_URL}/content_targets/${contentId}`,
    {
      'x-access-token': appAuthToken,
    },
    {
      targetAudienceId,
      targetTopicId,
    }
  );
};

// export const updateContentTarget = ({
//   appAuthToken,
//   contentId,
//   targetAudienceId,
//   targetTopicId,
//   order,
// }) => {
//   return httpPut(
//     `${API_BASE_URL}/content_targets/${contentId}`,
//     {
//       'x-access-token': appAuthToken,
//     },
//     {
//       targetAudienceId,
//       targetTopicId,
//       order,
//     }
//   );
// };

export const removeContentTarget = ({
  appAuthToken,
  contentId,
  targetAudienceId,
  targetTopicId,
}) => {
  return httpDelete(
    `${API_BASE_URL}/content_targets/${contentId}`,
    {
      'x-access-token': appAuthToken,
    },
    {
      targetAudienceId,
      targetTopicId,
    }
  );
};
