import * as Api from '../../api';

export const getRecentWordsLearnedUserCountHistory =
  ({ periodType, periodCount, contentId, groupId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getRecentWordsLearnedUserCountHistory({
        appAuthToken,
        periodType,
        periodCount,
        contentId,
        groupId,
      });
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

export const getRecentWordsRememberedUserCountHistory =
  ({ periodType, periodCount, contentId, groupId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getRecentWordsRememberedUserCountHistory({
        appAuthToken,
        periodType,
        periodCount,
        contentId,
        groupId,
      });
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

export const getContentsWordsLearned =
  ({ contentIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getContentsWordsLearned({
        appAuthToken,
        contentIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get contents words learned';
    }
  };

export const getContentsQuizzesTaken =
  ({ contentIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getContentsQuizzesTaken({
        appAuthToken,
        contentIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get contents quizzes taken');
    }
  };

export const getContentsLearnedUsers =
  ({ contentIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getContentsLearnedUsers({
        appAuthToken,
        contentIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get contents learned users';
    }
  };

export const getContentsQuizzesTakenUsers =
  ({ contentIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getContentsQuizzesTakenUsers({
        appAuthToken,
        contentIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get contents quizzes taken users');
    }
  };

export const getRecentWordsLearnedHistoryByContents =
  ({ periodType, periodCount, contentIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getRecentWordsLearnedHistoryByContents({
        appAuthToken,
        periodType,
        periodCount,
        contentIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get recent words learned history by contents';
    }
  };

export const getRecentQuizzesTakenHistoryByContents =
  ({ periodType, periodCount, contentIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getRecentQuizzesTakenHistoryByContents({
        appAuthToken,
        periodType,
        periodCount,
        contentIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get recent quizzes taken history by contents');
    }
  };

export const getRecentLearnedUsersHistoryByContents =
  ({ periodType, periodCount, contentIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getRecentLearnedUsersHistoryByContents({
        appAuthToken,
        periodType,
        periodCount,
        contentIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get recent learned users history by contents';
    }
  };

export const getRecentQuizzesTakenUsersHistoryByContents =
  ({ periodType, periodCount, contentIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getRecentQuizzesTakenUsersHistoryByContents({
        appAuthToken,
        periodType,
        periodCount,
        contentIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get recent quizzes taken users history by contents');
    }
  };
