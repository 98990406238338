export function hexToBuf(hex) {
  for (var bytes = [], c = 0; c < hex.length; c += 2)
    bytes.push(parseInt(hex.substr(c, 2), 16));
  return new Uint8Array(bytes);
}

export function bufToHex(buf) {
  var byteArray = new Uint8Array(buf);
  var hexString = '';
  var nextHexByte;

  for (var i = 0; i < byteArray.byteLength; i++) {
    nextHexByte = byteArray[i].toString(16);
    if (nextHexByte.length < 2) {
      nextHexByte = '0' + nextHexByte;
    }
    hexString += nextHexByte;
  }
  return hexString;
}

export function strToBuf(str) {
  return new TextEncoder().encode(str);
}
