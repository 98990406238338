import * as LearnGroupStatsApi from '../../api/learn_group_stats';

export const getLearnGroupWordsLearnedUsers = ({
  learnGroupId,
  from,
  to,
  order,
  offset,
  limit,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await LearnGroupStatsApi.getLearnGroupWordsLearnedUsers({
      appAuthToken,
      learnGroupId,
      from,
      to,
      order,
      offset,
      limit,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw new Error('Cannot get learn group words learned users');
  }
};

export const getLearnGroupUserWordsLearned = ({
  learnGroupId,
  userId,
  from,
  to,
  order,
  offset,
  limit,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await LearnGroupStatsApi.getLearnGroupUserWordsLearned({
      appAuthToken,
      learnGroupId,
      userId,
      from,
      to,
      order,
      offset,
      limit,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw new Error('Cannot get learn group user words learned');
  }
};

export const getLearnGroupQuizResultsUsers = ({
  learnGroupId,
  from,
  to,
  order,
  offset,
  limit,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await LearnGroupStatsApi.getLearnGroupQuizResultsUsers({
      appAuthToken,
      learnGroupId,
      from,
      to,
      order,
      offset,
      limit,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw new Error('Cannot get learn group quiz results users');
  }
};

export const getLearnGroupUsersQuizResults = ({
  learnGroupId,
  userId,
  from,
  to,
  order,
  offset,
  limit,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await LearnGroupStatsApi.getLearnGroupUsersQuizResults({
      appAuthToken,
      learnGroupId,
      userId,
      from,
      to,
      order,
      offset,
      limit,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw new Error('Cannot get learn group users quiz results');
  }
};

export const getLearnGroupUsersQuizAnswersFileExport = ({
  fileType,
  learnGroupId,
  userId,
  from,
  to,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await LearnGroupStatsApi.getLearnGroupUsersQuizAnswersFileExport(
      {
        appAuthToken,
        fileType,
        learnGroupId,
        userId,
        from,
        to,
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw new Error('Cannot get learn group users quiz answers file export');
  }
};

export const getLearnGroupQuizQuestionQuizResultsUsers = ({
  learnGroupId,
  from,
  to,
  order,
  offset,
  limit,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await LearnGroupStatsApi.getLearnGroupQuizQuestionQuizResultsUsers(
      {
        appAuthToken,
        learnGroupId,
        from,
        to,
        order,
        offset,
        limit,
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw new Error('Cannot get learn group quiz question quiz results users');
  }
};

export const getLearnGroupUsersQuizQuestionQuizResults = ({
  learnGroupId,
  userId,
  from,
  to,
  order,
  offset,
  limit,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await LearnGroupStatsApi.getLearnGroupUsersQuizQuestionQuizResults(
      {
        appAuthToken,
        learnGroupId,
        userId,
        from,
        to,
        order,
        offset,
        limit,
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw new Error('Cannot get learn group users quiz question quiz results');
  }
};

export const getLearnGroupUsersQuizQuestionQuizAnswersFileExport = ({
  fileType,
  learnGroupId,
  userId,
  from,
  to,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await LearnGroupStatsApi.getLearnGroupUsersQuizQuestionQuizAnswersFileExport(
      {
        appAuthToken,
        fileType,
        learnGroupId,
        userId,
        from,
        to,
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw new Error(
      'Cannot get learn group users quiz question quiz answers file export'
    );
  }
};
