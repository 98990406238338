import queryString from 'query-string';

import { httpGet, httpPost, httpPut, httpDelete } from './common';

import { API_BASE_URL } from '../constants';

export const getSubgroups = ({
  appAuthToken,
  groupId,
  search,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/subgroups?${queryString.stringify({
      groupId,
      search,
      offset,
      limit,
      sort,
      sortOrder,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getSubgroup = ({ appAuthToken, subgroupId }) => {
  return httpGet(`${API_BASE_URL}/subgroups/${subgroupId}`, {
    'x-access-token': appAuthToken,
  });
};

export const createSubgroup = ({ appAuthToken, name, pictureUrl, groupId }) => {
  return httpPost(
    `${API_BASE_URL}/subgroups`,
    {
      'x-access-token': appAuthToken,
    },
    { name, pictureUrl, groupId }
  );
};

export const updateSubgroup = ({
  appAuthToken,
  subgroupId,
  name,
  pictureUrl,
  groupId,
}) => {
  return httpPut(
    `${API_BASE_URL}/subgroups/${subgroupId}`,
    {
      'x-access-token': appAuthToken,
    },
    { name, pictureUrl, groupId }
  );
};

export const removeSubgroup = ({ appAuthToken, subgroupId }) => {
  return httpDelete(`${API_BASE_URL}/subgroups/${subgroupId}`, {
    'x-access-token': appAuthToken,
  });
};

export const getUsersBySubgroup = ({
  appAuthToken,
  subgroupId,
  search,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/subgroups/${subgroupId}/users?${queryString.stringify({
      search,
      offset,
      limit,
      sort,
      sortOrder,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const addUsersToSubgroup = ({ appAuthToken, subgroupId, userIds }) => {
  return httpPost(
    `${API_BASE_URL}/subgroups/${subgroupId}/users`,
    {
      'x-access-token': appAuthToken,
    },
    {
      userIds,
    }
  );
};

export const removeUserFromSubgroup = ({
  appAuthToken,
  subgroupId,
  userId,
}) => {
  return httpDelete(`${API_BASE_URL}/subgroups/${subgroupId}/users/${userId}`, {
    'x-access-token': appAuthToken,
  });
};
