import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import rootReducer from './reducers';
import rootSaga from './sagas';

// Log only in development environment
// const loggerMiddleware = createLogger({ predicate: (getState, action) => __DEV__  });
const loggerMiddleware = createLogger();

const sagaMiddleware = createSagaMiddleware();

export default function configureStore (initialState, thunkExtraArgs) {
  let middlewares = [sagaMiddleware, thunkMiddleware];
  // let middlewares = [sagaMiddleware];
  if (process.env.NODE_ENV === 'development') {
    middlewares = [...middlewares, loggerMiddleware];
  }

  const store = createStore(
    rootReducer, 
    initialState, 
    applyMiddleware(...middlewares)
  );

  sagaMiddleware.run(rootSaga);

  // if (module.hot) {
  //   module.hot.accept(() => {
  //     const nextRootReducer = require('./reducers/index').default;
  //     store.replaceReducer(nextRootReducer);
  //   });
  // }

  return store;
}
