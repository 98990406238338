import * as CmsContentApi from '../../api/cms_content';

export const getContents =
  ({
    search,
    categoryIds,
    targetTopicId,
    targetAudienceId,
    offset,
    limit,
    sort,
    sortOrder,
    includeUnpublished,
  } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsContentApi.getContents({
        appAuthToken,
        search,
        categoryIds,
        targetTopicId,
        targetAudienceId,
        offset,
        limit,
        sort,
        sortOrder,
        includeUnpublished,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get contents';
    }
  };

export const getContent =
  ({ id } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsContentApi.getContent({ appAuthToken, id });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get content';
    }
  };

export const createContent =
  ({ formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsContentApi.createContent({
        appAuthToken,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error creating content';
    }
  };

export const updateContent =
  ({ id, formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsContentApi.updateContent({
        appAuthToken,
        id,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error updating content';
    }
  };

export const deleteContent =
  ({ id }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsContentApi.deleteContent({ appAuthToken, id });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot delete content';
    }
  };

export const cloneContent =
  ({
    id,
    includeLearnGroups,
    includeLearnDataInLearnGroup,
    includeQuizQuestionsInLearnGroup,
  }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsContentApi.cloneContent({
        appAuthToken,
        id,
        includeLearnGroups,
        includeLearnDataInLearnGroup,
        includeQuizQuestionsInLearnGroup,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Error cloning content');
    }
  };

export const setContentUpdatedAt =
  ({ id, scheduled, scheduledSetUpdatedAtDatetime }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsContentApi.setContentUpdatedAt({
        appAuthToken,
        id,
        scheduled,
        scheduledSetUpdatedAtDatetime,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot set content updated at');
    }
  };

export const cancelScheduledSetContentUpdatedAt =
  ({ id }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsContentApi.cancelScheduledSetContentUpdatedAt({
        appAuthToken,
        id,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot cancel scheduled set content updated at');
    }
  };

export const addLearnGroupToContent =
  ({ contentId, learnGroupId, order }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsContentApi.addLearnGroupToContent({
        appAuthToken,
        contentId,
        learnGroupId,
        order,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error adding learn group to content';
    }
  };

export const updateContentLearnGroup =
  ({ contentId, learnGroupId, order }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsContentApi.updateContentLearnGroup({
        appAuthToken,
        contentId,
        learnGroupId,
        order,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error updating learn group for content';
    }
  };

export const removeLearnGroupFromContent =
  ({ contentId, learnGroupId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsContentApi.removeLearnGroupFromContent({
        appAuthToken,
        contentId,
        learnGroupId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error removing learn group from content';
    }
  };
