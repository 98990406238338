import * as CmsApi from '../../api/cms';

export const getLearnGroups =
  ({
    search,
    offset,
    limit,
    sort,
    sortOrder,
    notPublished,
    reviewPending,
    proofreadPending,
  } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.getLearnGroups({
        appAuthToken,
        search,
        offset,
        limit,
        sort,
        sortOrder,
        notPublished,
        reviewPending,
        proofreadPending,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get learn groups';
    }
  };

export const getLearnGroup =
  ({ id } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.getLearnGroup({ appAuthToken, id });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get learn group';
    }
  };

export const createLearnGroup =
  ({ formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.createLearnGroup({
        appAuthToken,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error creating learn group';
    }
  };

export const updateLearnGroup =
  ({ id, formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.updateLearnGroup({
        appAuthToken,
        id,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error updating learn group';
    }
  };

export const deleteLearnGroup =
  ({ id }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.deleteLearnGroup({ appAuthToken, id });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot delete learn group';
    }
  };

export const publishLearnGroup =
  ({ id, publish, scheduledPublishDatetime }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      await CmsApi.publishLearnGroup({
        appAuthToken,
        id,
        publish,
        scheduledPublishDatetime,
      });
    } catch (error) {
      console.error(error);
      throw 'Error publishing learn group';
    }
  };

export const unpublishLearnGroup =
  ({ id }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      await CmsApi.unpublishLearnGroup({
        appAuthToken,
        id,
      });
    } catch (error) {
      console.error(error);
      throw new Error('Error unpublishing learn group');
    }
  };

export const approveLearnGroup =
  ({ id, publish, scheduledPublishDatetime }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      await CmsApi.approveLearnGroup({
        appAuthToken,
        id,
        publish,
        scheduledPublishDatetime,
      });
    } catch (error) {
      console.error(error);
      throw 'Error approving learn group';
    }
  };

export const proofreadLearnGroup =
  ({ id }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      await CmsApi.proofreadLearnGroup({
        appAuthToken,
        id,
      });
    } catch (error) {
      console.error(error);
      throw 'Error proofreading learn group';
    }
  };

export const cloneLearnGroupAndLearnData =
  ({ id, includeLearnData, includeQuizQuestions }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.cloneLearnGroupAndLearnData({
        appAuthToken,
        id,
        includeLearnData,
        includeQuizQuestions,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot clone learn group';
    }
  };

export const getVocabs =
  ({ search, contentId, learnGroupId, offset, limit, sort, sortOrder } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.getVocabs({
        appAuthToken,
        search,
        contentId,
        learnGroupId,
        offset,
        limit,
        sort,
        sortOrder,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get vocabs';
    }
  };

export const getVocab =
  ({ id, limitAdjacentIdToLearnGroupId } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.getVocab({
        appAuthToken,
        id,
        limitAdjacentIdToLearnGroupId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get vocab';
    }
  };

export const updateVocab =
  ({ id, formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.updateVocab({
        appAuthToken,
        id,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error updating vocab data';
    }
  };

export const deleteVocab =
  ({ id }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.deleteVocab({ appAuthToken, id });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot delete vocab';
    }
  };

export const cloneVocab =
  ({ id, destinationLearnGroupId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.cloneVocab({
        appAuthToken,
        id,
        destinationLearnGroupId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot clone vocab');
    }
  };

export const checkLearnDataIdsExistence =
  ({ ids }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.checkLearnDataIdsExistence({
        appAuthToken,
        ids,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error checking learn data IDs existence';
    }
  };

export const createLearnData =
  ({ formData, onProgress } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.createLearnData({
        appAuthToken,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error creating learn data';
    }
  };

export const uploadLearnDataBatch =
  ({ formData, onProgress } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.uploadLearnDataBatch({
        appAuthToken,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error uploading learn data';
    }
  };

export const checkTagsExistence =
  ({ tags }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.checkTagsExistence({
        appAuthToken,
        tags,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error checking tags existence';
    }
  };

export const getTags =
  ({ search, offset, limit, sort, sortOrder } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.getTags({
        appAuthToken,
        search,
        offset,
        limit,
        sort,
        sortOrder,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get tags';
    }
  };

export const createTag =
  ({ tag } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.createTag({ appAuthToken, tag });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot create tag';
    }
  };

export const getTag =
  ({ id } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.getTag({ appAuthToken, id });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get tag';
    }
  };

export const updateTag =
  ({ id, tag } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.updateTag({ appAuthToken, id, tag });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot update tag';
    }
  };

export const removeTag =
  ({ id } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.removeTag({ appAuthToken, id });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot remove tag';
    }
  };

export const getCategories =
  ({ search, offset, limit, sort, sortOrder } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.getCategories({
        appAuthToken,
        search,
        offset,
        limit,
        sort,
        sortOrder,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get categories';
    }
  };

export const createCategory =
  ({ category } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.createCategory({ appAuthToken, category });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot create category';
    }
  };

export const getCategory =
  ({ id } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.getCategory({ appAuthToken, id });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get category';
    }
  };

export const updateCategory =
  ({ id, category } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.updateCategory({
        appAuthToken,
        id,
        category,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot update category';
    }
  };

export const removeCategory =
  ({ id } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.removeCategory({ appAuthToken, id });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot remove category';
    }
  };

export const getFeaturedTitleIds = () => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await CmsApi.getFeaturedTitleIds({ appAuthToken });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot get featured title IDs';
  }
};

export const setFeaturedTitleIds =
  ({ config } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.setFeaturedTitleIds({
        appAuthToken,
        config,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot set featured title IDs';
    }
  };

export const getDiscoverScreenCategoryIds =
  () => async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.getDiscoverScreenCategoryIds({
        appAuthToken,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get discover screen category IDs';
    }
  };

export const setDiscoverScreenCategoryIds =
  ({ config } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.setDiscoverScreenCategoryIds({
        appAuthToken,
        config,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot set discover screen category IDs';
    }
  };

export const getScheduledTasks =
  ({ offset, limit, sort, sortOrder } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.getScheduledTasks({
        appAuthToken,
        offset,
        limit,
        sort,
        sortOrder,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get scheduled tasks';
    }
  };

export const getScheduledTask =
  ({ id } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsApi.getScheduledTask({ appAuthToken, id });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get scheduled task';
    }
  };
