import queryString from 'query-string';

import { httpGet, httpDelete, upload } from './common';

import { API_BASE_URL } from '../constants';

export const getTargetAudiences = ({
  appAuthToken,
  search,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/target_audiences?${queryString.stringify({
      search,
      offset,
      limit,
      sort,
      sortOrder,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getTargetAudience = ({ appAuthToken, targetAudienceId }) => {
  return httpGet(`${API_BASE_URL}/target_audiences/${targetAudienceId}`, {
    'x-access-token': appAuthToken,
  });
};

export const createTargetAudience = ({
  appAuthToken,
  formData,
  onProgress,
}) => {
  return upload({
    method: 'POST',
    url: `${API_BASE_URL}/target_audiences`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export const updateTargetAudience = ({
  appAuthToken,
  targetAudienceId,
  formData,
  onProgress,
}) => {
  return upload({
    method: 'PUT',
    url: `${API_BASE_URL}/target_audiences/${targetAudienceId}`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export const removeTargetAudience = ({ appAuthToken, targetAudienceId }) => {
  return httpDelete(`${API_BASE_URL}/target_audiences/${targetAudienceId}`, {
    'x-access-token': appAuthToken,
  });
};
