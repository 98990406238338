import * as RedemptionCodeApi from '../../api/redemptionCode';

export const getRedemptionCodes = ({
  groupId,
  search,
  offset,
  limit,
  sort,
  sortOrder,
} = {}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await RedemptionCodeApi.getRedemptionCodes({
      appAuthToken,
      groupId,
      search,
      offset,
      limit,
      sort,
      sortOrder,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot get redemption codes';
  }
};

export const getRedemptionCode = ({ id }) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await RedemptionCodeApi.getRedemptionCode({
      appAuthToken,
      id,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot get redemption code';
  }
};

export const createRedemptionCode = ({ redemptionCode }) => async (
  dispatch,
  getState
) => {
  const { appAuthToken } = getState();
  try {
    const response = await RedemptionCodeApi.createRedemptionCode({
      appAuthToken,
      redemptionCode,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot create redemption code';
  }
};

export const updateRedemptionCode = ({ id, redemptionCode }) => async (
  dispatch,
  getState
) => {
  const { appAuthToken } = getState();
  try {
    const response = await RedemptionCodeApi.updateRedemptionCode({
      appAuthToken,
      id,
      redemptionCode,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot update redemption code';
  }
};

export const removeRedemptionCode = ({ id }) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await RedemptionCodeApi.removeRedemptionCode({
      appAuthToken,
      id,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot remove redemption code';
  }
};
