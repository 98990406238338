import {
  // SET_USER,
  LOGIN_SUCCEED,
  LOGOUT,
  LOGOUT_SUCCEED,
} from '../actions/types';

export const user = (state = null, action) => {
  switch (action.type) {
    case LOGIN_SUCCEED:
      return action.user;
    case LOGOUT_SUCCEED:
      return null;
    default:
      return state;
  }
};
