import * as SubgroupApi from '../../api/subgroup';

export const getSubgroups = ({
  groupId,
  search,
  offset,
  limit,
  sort,
  sortOrder,
} = {}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await SubgroupApi.getSubgroups({
      appAuthToken,
      groupId,
      search,
      offset,
      limit,
      sort,
      sortOrder,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot get subgroups';
  }
};

export const getSubgroup = ({ subgroupId }) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await SubgroupApi.getSubgroup({
      appAuthToken,
      subgroupId,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot get subgroup';
  }
};

export const createSubgroup = ({ name, pictureUrl, groupId }) => async (
  dispatch,
  getState
) => {
  const { appAuthToken } = getState();
  try {
    const response = await SubgroupApi.createSubgroup({
      appAuthToken,
      name,
      pictureUrl,
      groupId,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot create subgroup';
  }
};

export const updateSubgroup = ({
  subgroupId,
  name,
  pictureUrl,
  groupId,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await SubgroupApi.updateSubgroup({
      appAuthToken,
      subgroupId,
      name,
      pictureUrl,
      groupId,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot update subgroup';
  }
};

export const removeSubgroup = ({ subgroupId }) => async (
  dispatch,
  getState
) => {
  const { appAuthToken } = getState();
  try {
    const response = await SubgroupApi.removeSubgroup({
      appAuthToken,
      subgroupId,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot remove subgroup';
  }
};

export const getUsersBySubgroup = ({
  subgroupId,
  search,
  offset,
  limit,
  sort,
  sortOrder,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await SubgroupApi.getUsersBySubgroup({
      appAuthToken,
      subgroupId,
      search,
      offset,
      limit,
      sort,
      sortOrder,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot get users by subgroup';
  }
};

export const addUsersToSubgroup = ({ subgroupId, userIds }) => async (
  dispatch,
  getState
) => {
  const { appAuthToken } = getState();
  try {
    const response = await SubgroupApi.addUsersToSubgroup({
      appAuthToken,
      subgroupId,
      userIds,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot add users to subgroup';
  }
};

export const removeUserFromSubgroup = ({ subgroupId, userId }) => async (
  dispatch,
  getState
) => {
  const { appAuthToken } = getState();
  try {
    const response = await SubgroupApi.removeUserFromSubgroup({
      appAuthToken,
      subgroupId,
      userId,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot remove user from subgroup';
  }
};
