import { httpGet, httpPost, upload } from './common';

import { API_BASE_URL } from '../constants';

export const getAppAnnouncement = ({ appAuthToken }) => {
  return httpPost(`${API_BASE_URL}/get_app_announcement`, {
    'x-access-token': appAuthToken,
  });
};

export const setAppAnnouncement = ({ appAuthToken, formData, onProgress }) => {
  return upload({
    method: 'POST',
    url: `${API_BASE_URL}/set_app_announcement`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export function removeAppAnnouncement({ appAuthToken }) {
  return httpPost(`${API_BASE_URL}/remove_app_announcement`, {
    'x-access-token': appAuthToken,
  });
}
