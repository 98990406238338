import queryString from 'query-string';

import { httpGet, httpPost } from './common';

import { API_BASE_URL } from '../constants';

export const getContentsSortableWordsLearned = ({
  appAuthToken,
  search,
  categoryIds,
  targetTopicId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/contentsSortableWordsLearned?${queryString.stringify(
      {
        search,
        order,
        categoryIds,
        targetTopicId,
        targetAudienceId,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getContentsSortableQuizzesTaken = ({
  appAuthToken,
  search,
  categoryIds,
  targetTopicId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/contentsSortableQuizzesTaken?${queryString.stringify(
      {
        search,
        categoryIds,
        targetTopicId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getContentsSortableLearnedUsers = ({
  appAuthToken,
  search,
  categoryIds,
  targetTopicId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/contentsSortableLearnedUsers?${queryString.stringify(
      {
        search,
        categoryIds,
        targetTopicId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getContentsSortableQuizzesTakenUsers = ({
  appAuthToken,
  search,
  categoryIds,
  targetTopicId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/contentsSortableQuizzesTakenUsers?${queryString.stringify(
      {
        search,
        categoryIds,
        targetTopicId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getContentsSortableWordsLearnedInPeriod = ({
  appAuthToken,
  datetime,
  periodType,
  search,
  categoryIds,
  targetTopicId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/contentsSortableWordsLearnedInPeriod?${queryString.stringify(
      {
        datetime,
        periodType,
        search,
        categoryIds,
        targetTopicId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getContentsSortableQuizzesTakenInPeriod = ({
  appAuthToken,
  datetime,
  periodType,
  search,
  categoryIds,
  targetTopicId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/contentsSortableQuizzesTakenInPeriod?${queryString.stringify(
      {
        datetime,
        periodType,
        search,
        categoryIds,
        targetTopicId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getContentsSortableLearnedUsersInPeriod = ({
  appAuthToken,
  datetime,
  periodType,
  search,
  categoryIds,
  targetTopicId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/contentsSortableLearnedUsersInPeriod?${queryString.stringify(
      {
        datetime,
        periodType,
        search,
        categoryIds,
        targetTopicId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getContentsSortableQuizzesTakenUsersInPeriod = ({
  appAuthToken,
  datetime,
  periodType,
  search,
  categoryIds,
  targetTopicId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/contentsSortableQuizzesTakenUsersInPeriod?${queryString.stringify(
      {
        datetime,
        periodType,
        search,
        categoryIds,
        targetTopicId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};
