import * as TargetAudienceApi from '../../api/target_audience';

export const getTargetAudiences =
  ({ search, offset, limit, sort, sortOrder }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await TargetAudienceApi.getTargetAudiences({
        appAuthToken,
        search,
        offset,
        limit,
        sort,
        sortOrder,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get target audiences');
    }
  };

export const getTargetAudience =
  ({ targetAudienceId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await TargetAudienceApi.getTargetAudience({
        appAuthToken,
        targetAudienceId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get target audience');
    }
  };

export const createTargetAudience =
  ({ formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await TargetAudienceApi.createTargetAudience({
        appAuthToken,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot create target audience');
    }
  };

export const updateTargetAudience =
  ({ targetAudienceId, formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await TargetAudienceApi.updateTargetAudience({
        appAuthToken,
        targetAudienceId,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot update target audience');
    }
  };

export const removeTargetAudience =
  ({ targetAudienceId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await TargetAudienceApi.removeTargetAudience({
        appAuthToken,
        targetAudienceId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot remove target audience');
    }
  };
