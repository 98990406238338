import * as SalesStatsApi from '../../api/salesStats';

export const getSalesCountSummary =
  ({ groupId, targetAudienceId, order }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await SalesStatsApi.getSalesCountSummary({
        appAuthToken,
        groupId,
        targetAudienceId,
        order,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get sales count summary');
    }
  };

export const getRecentSalesSummaryHistory =
  ({ periodType, periodCount, groupId, targetAudienceId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await SalesStatsApi.getRecentSalesSummaryHistory({
        appAuthToken,
        periodType,
        periodCount,
        groupId,
        targetAudienceId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get recent sales summary history');
    }
  };

export const getSalesUserCountSummary =
  ({ groupId, targetAudienceId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await SalesStatsApi.getSalesUserCountSummary({
        appAuthToken,
        groupId,
        targetAudienceId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get sales user count summary');
    }
  };

export const getRecentSalesUserCountSummaryHistory =
  ({ periodType, periodCount, groupId, targetAudienceId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response =
        await SalesStatsApi.getRecentSalesUserCountSummaryHistory({
          appAuthToken,
          periodType,
          periodCount,
          groupId,
          targetAudienceId,
        });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get recent sales user count summary history');
    }
  };

export const getUsersSortableSalesSummaryInPeriod =
  ({
    datetime,
    periodType,
    search,
    groupId,
    targetAudienceId,
    order,
    offset,
    limit,
  }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await SalesStatsApi.getUsersSortableSalesSummaryInPeriod(
        {
          appAuthToken,
          datetime,
          periodType,
          search,
          groupId,
          targetAudienceId,
          order,
          offset,
          limit,
        }
      );
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get users sortable sales summary in period');
    }
  };

export const getRecentSalesSummaryHistoryByUsers =
  ({ periodType, periodCount, userIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await SalesStatsApi.getRecentSalesSummaryHistoryByUsers({
        appAuthToken,
        periodType,
        periodCount,
        userIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get recent sales summary history by users');
    }
  };
