export const INITIALIZE_APP = 'INITIALIZE_APP';
export const APP_INITIALIZED = 'APP_INITIALIZED';

export const SET_USER = 'SET_USER';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCEED = 'LOGIN_SUCCEED';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCEED = 'LOGOUT_SUCCEED';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCEED = 'CHANGE_PASSWORD_SUCCEED';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const RESET_CHANGE_PASSWORD_STATUS = 'RESET_CHANGE_PASSWORD_STATUS';
