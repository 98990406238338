import srpClient from 'secure-remote-password/client';

import pbkdf2 from './pbkdf2';
import { hexToBuf, bufToHex, strToBuf } from './buffer';

async function deriveSrpPrivateKey(username, password, salt, iterations) {
  if (!username) throw new Error('No username provided');
  if (!password) throw new Error('No password provided');

  if (typeof salt === 'string') {
    salt = hexToBuf(salt);
  }

  const srpPrivatekey = await pbkdf2(
    strToBuf(`${username}:${password}`),
    salt,
    iterations,
    256,
    'SHA-512'
  );

  return bufToHex(srpPrivatekey);
}

export async function generateCredential({ username, password }) {
  const salt = srpClient.generateSalt();
  const iterations = 100000;
  const privateKey = await deriveSrpPrivateKey(
    username,
    password,
    salt,
    iterations
  );
  const verifier = srpClient.deriveVerifier(privateKey);
  return { verifier, salt, iterations };
}

export function verifyStepOneClient() {
  const clientEphemeral = srpClient.generateEphemeral();

  const {
    secret: clientSecretEphemeral,
    public: clientPublicEphemeral,
  } = clientEphemeral;

  return { clientSecretEphemeral, clientPublicEphemeral };
}

export async function verifyStepTwoClient({
  username,
  password,
  iterations,
  salt,
  clientSecretEphemeral,
  serverPublicEphemeral,
}) {
  const privateKey = await deriveSrpPrivateKey(
    username,
    password,
    salt,
    iterations
  );
  const clientSession = srpClient.deriveSession(
    clientSecretEphemeral,
    serverPublicEphemeral,
    salt,
    username,
    privateKey
  );

  const { proof: clientSessionProof } = clientSession;

  return { clientSession, clientSessionProof };
}

export function clientVerifyServerProof({
  clientPublicEphemeral,
  clientSession,
  serverSessionProof,
}) {
  try {
    srpClient.verifySession(
      clientPublicEphemeral,
      clientSession,
      serverSessionProof
    );
  } catch (error) {
    console.error(error);
    throw new Error('Error verifying server session');
  }
}
