import './Loading.scss';

import React, { Component } from 'react';

class Loading extends Component {
  render() {
    if (this.props.error) {
      return (
        <div className="Loading">
          Error! <button onClick={this.props.retry}>Retry</button>
        </div>
      );
    } else {
      return (
        <div className="Loading">
          <div className="lds-ellipsis">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      );
    }
  }
}

export default Loading;
