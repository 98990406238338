import * as Api from '../../api';
import * as UserApi from '../../api/user';

export const getUsers =
  ({
    search,
    groupId,
    targetAudienceId,
    offset,
    limit,
    sort,
    sortOrder,
  } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getUsers({
        appAuthToken,
        search,
        groupId,
        targetAudienceId,
        offset,
        limit,
        sort,
        sortOrder,
      });
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

export const getUser =
  ({ id }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getUser({ appAuthToken, id });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get user';
    }
  };

export const setUserStaff =
  ({ userId, isStaff }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserApi.setUserStaff({
        appAuthToken,
        userId,
        isStaff,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot set user staff';
    }
  };

export const addUserToManagementUser =
  ({ managementUserId, userId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserApi.addUserToManagementUser({
        appAuthToken,
        managementUserId,
        userId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot add user to management user';
    }
  };

export const removeUserFromManagementUser =
  ({ managementUserId, userId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserApi.removeUserFromManagementUser({
        appAuthToken,
        managementUserId,
        userId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot remove user from management user';
    }
  };

export const resetUser =
  ({ id }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.resetUser({ appAuthToken, id });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot reset user';
    }
  };

export const deleteUserAccountData =
  ({ userId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.deleteUserAccountData({
        appAuthToken,
        userId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot delete user account data');
    }
  };

export const getUserStats =
  ({ userId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getUserStats({ appAuthToken, userId });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get user stats';
    }
  };

export const getUserLearnHistory =
  ({ userId, periodType, periodCount }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getUserLearnHistory({
        appAuthToken,
        userId,
        periodType,
        periodCount,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get user learn history';
    }
  };

export const getUserDetailedLearnHistory =
  ({ userId, offset, limit }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getUserDetailedLearnHistory({
        appAuthToken,
        userId,
        offset,
        limit,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get user detailed learn history';
    }
  };

export const getUserAccuracyHistory =
  ({ userId, periodType, periodCount }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getUserAccuracyHistory({
        appAuthToken,
        userId,
        periodType,
        periodCount,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get user accuracy history';
    }
  };

export const getUserScoreHistory =
  ({ userId, periodType, periodCount }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getUserScoreHistory({
        appAuthToken,
        userId,
        periodType,
        periodCount,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get user score history';
    }
  };

export const getRecentWordsLearnedHistoryByUsers =
  ({ periodType, periodCount, userIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getRecentWordsLearnedHistoryByUsers({
        appAuthToken,
        periodType,
        periodCount,
        userIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get recent words learned history by users';
    }
  };

export const getRecentWordsRememberedHistoryByUsers =
  ({ periodType, periodCount, userIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getRecentWordsRememberedHistoryByUsers({
        appAuthToken,
        periodType,
        periodCount,
        userIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get recent words remembered history by users';
    }
  };

export const getRecentQuizTakenFirstTimeHistoryByUsers =
  ({ periodType, periodCount, userIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getRecentQuizTakenFirstTimeHistoryByUsers({
        appAuthToken,
        periodType,
        periodCount,
        userIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(
        'Cannot get recent quiz taken (first time) history by users'
      );
    }
  };

export const getRecentQuizTakenHistoryByUsers =
  ({ periodType, periodCount, userIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await Api.getRecentQuizTakenHistoryByUsers({
        appAuthToken,
        periodType,
        periodCount,
        userIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(
        'Cannot get recent quiz taken history by users'
      );
    }
  };
