import queryString from 'query-string';

import { httpGet, httpPost, httpPut, httpDelete, upload } from './common';

import { API_BASE_URL } from '../constants';

export const getQuizQuestions = ({
  appAuthToken,
  learnGroupId,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/quiz_questions?${queryString.stringify(
      {
        learnGroupId,
        offset,
        limit,
        sort,
        sortOrder,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getQuizQuestion = ({
  appAuthToken,
  id,
  limitAdjacentIdToLearnGroupId,
}) => {
  return httpGet(
    `${API_BASE_URL}/quiz_questions/${id}?${queryString.stringify({
      limitAdjacentIdToLearnGroupId,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const createQuizQuestion = ({ appAuthToken, formData, onProgress }) => {
  return upload({
    method: 'POST',
    url: `${API_BASE_URL}/quiz_questions`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export const updateQuizQuestion = ({
  appAuthToken,
  id,
  formData,
  onProgress,
}) => {
  return upload({
    method: 'PUT',
    url: `${API_BASE_URL}/quiz_questions/${id}`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export function removeQuizQuestion({ appAuthToken, id }) {
  return httpDelete(`${API_BASE_URL}/quiz_questions/${id}`, {
    'x-access-token': appAuthToken,
  });
}

export const cloneQuizQuestion = ({
  appAuthToken,
  id,
  destinationLearnGroupId,
}) => {
  return httpPost(
    `${API_BASE_URL}/quiz_questions/${id}/clone`,
    {
      'x-access-token': appAuthToken,
    },
    {
      destinationLearnGroupId,
    }
  );
};
