import * as DiscoverScreenApi from '../../api/discover_screen';

export const listDiscoverScreenSettings =
  ({ targetAudienceId, targetTopicId, offset, limit, sort, sortOrder }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await DiscoverScreenApi.listDiscoverScreenSettings({
        appAuthToken,
        targetAudienceId,
        targetTopicId,
        offset,
        limit,
        sort,
        sortOrder,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot list discover screen settings');
    }
  };

export const getDiscoverScreenSettings =
  ({ discoverScreenId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await DiscoverScreenApi.getDiscoverScreenSettings({
        appAuthToken,
        discoverScreenId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get discover screen settings');
    }
  };

export const createDiscoverScreenSettings =
  ({ featuredTitles, categories, active, targetAudienceId, targetTopicId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await DiscoverScreenApi.createDiscoverScreenSettings({
        appAuthToken,
        featuredTitles,
        categories,
        active,
        targetAudienceId,
        targetTopicId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot create discover screen settings');
    }
  };

export const updateDiscoverScreenSettings =
  ({ discoverScreenId, featuredTitles, categories, order, active }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await DiscoverScreenApi.updateDiscoverScreenSettings({
        appAuthToken,
        discoverScreenId,
        featuredTitles,
        categories,
        order,
        active,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot update discover screen settings');
    }
  };

export const removeDiscoverScreenSettings =
  ({ discoverScreenId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await DiscoverScreenApi.removeDiscoverScreenSettings({
        appAuthToken,
        discoverScreenId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot remove discover screen settings');
    }
  };
