import * as Api from '../../api';

export const getGroups = ({
  search,
  offset,
  limit,
  sort,
  sortOrder,
} = {}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.getGroups({
      appAuthToken,
      search,
      offset,
      limit,
      sort,
      sortOrder,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot get groups';
  }
};

export const getGroup = ({ groupId }) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.getGroup({ appAuthToken, groupId });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot get group';
  }
};

export const createGroup = ({ name, type, pictureUrl }) => async (
  dispatch,
  getState
) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.createGroup({
      appAuthToken,
      name,
      type,
      pictureUrl,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot create group';
  }
};

export const updateGroup = ({ groupId, name, pictureUrl, active }) => async (
  dispatch,
  getState
) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.updateGroup({
      appAuthToken,
      groupId,
      name,
      pictureUrl,
      active,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot update group';
  }
};

export const removeGroup = ({ groupId }) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.removeGroup({
      appAuthToken,
      groupId,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot remove group';
  }
};

export const getUsersByGroup = ({ groupId, search, offset, limit }) => async (
  dispatch,
  getState
) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.getUsersByGroup({
      appAuthToken,
      groupId,
      search,
      offset,
      limit,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot get users by group';
  }
};

export const addUsersToGroup = ({ groupId, userIds }) => async (
  dispatch,
  getState
) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.addUsersToGroup({
      appAuthToken,
      groupId,
      userIds,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot add users to group';
  }
};

export const removeUserFromGroup = ({ groupId, userId }) => async (
  dispatch,
  getState
) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.removeUserFromGroup({
      appAuthToken,
      groupId,
      userId,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot remove user from group';
  }
};
