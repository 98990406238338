import {
  LOGIN,
  LOGOUT,
  CHANGE_PASSWORD,
  RESET_CHANGE_PASSWORD_STATUS,
} from './types';

export const login = ({ username, password }) => {
  return {
    type: LOGIN,
    username,
    password,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const changePassword = ({ currentPassword, newPassword }) => {
  return {
    type: CHANGE_PASSWORD,
    currentPassword,
    newPassword,
  };
};

export const resetChangePasswordStatus = () => {
  return {
    type: RESET_CHANGE_PASSWORD_STATUS,
  };
};
