import * as Api from '../../api';

export const getStatsSummary = () => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.getStatsSummary({
      appAuthToken,
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getRecentNewUsersHistory = ({
  periodType,
  periodCount,
  groupId,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.getRecentNewUsersHistory({
      appAuthToken,
      periodType,
      periodCount,
      groupId,
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getRecentWordsLearnedHistory = ({
  periodType,
  periodCount,
  contentId,
  groupId,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.getRecentWordsLearnedHistory({
      appAuthToken,
      periodType,
      periodCount,
      contentId,
      groupId,
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getRecentWordsRememberedHistory = ({
  periodType,
  periodCount,
  contentId,
  groupId,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.getRecentWordsRememberedHistory({
      appAuthToken,
      periodType,
      periodCount,
      contentId,
      groupId,
    });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};
