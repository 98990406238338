import queryString from 'query-string';

import { httpGet, httpGetDownloadFile, httpPost } from './common';

import { API_BASE_URL } from '../constants';

export const getUsersWordsLearned = ({ appAuthToken, userIds }) => {
  return httpPost(
    `${API_BASE_URL}/stats/usersWordsLearned`,
    {
      'x-access-token': appAuthToken,
    },
    {
      userIds,
    }
  );
};

export const getUsersWordsRemembered = ({ appAuthToken, userIds }) => {
  return httpPost(
    `${API_BASE_URL}/stats/usersWordsRemembered`,
    {
      'x-access-token': appAuthToken,
    },
    {
      userIds,
    }
  );
};

export const getUsersQuizTakenFirstTime = ({ appAuthToken, userIds }) => {
  return httpPost(
    `${API_BASE_URL}/stats/usersQuizTakenFirstTime`,
    {
      'x-access-token': appAuthToken,
    },
    {
      userIds,
    }
  );
};

export const getUsersQuizTaken = ({ appAuthToken, userIds }) => {
  return httpPost(
    `${API_BASE_URL}/stats/usersQuizTaken`,
    {
      'x-access-token': appAuthToken,
    },
    {
      userIds,
    }
  );
};

export const getUsersScore = ({ appAuthToken, userIds }) => {
  return httpPost(
    `${API_BASE_URL}/stats/usersScore`,
    {
      'x-access-token': appAuthToken,
    },
    {
      userIds,
    }
  );
};

export const getUsersSortableWordsLearned = ({
  appAuthToken,
  search,
  groupId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/usersSortableWordsLearned?${queryString.stringify(
      {
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getUsersSortableWordsRemembered = ({
  appAuthToken,
  search,
  groupId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/usersSortableWordsRemembered?${queryString.stringify(
      {
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getUsersSortableQuizTakenFirstTime = ({
  appAuthToken,
  search,
  groupId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/usersSortableQuizTakenFirstTime?${queryString.stringify(
      {
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getUsersSortableQuizTaken = ({
  appAuthToken,
  search,
  groupId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/usersSortableQuizTaken?${queryString.stringify(
      {
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getUsersSortableScore = ({
  appAuthToken,
  search,
  groupId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/usersSortableScore?${queryString.stringify(
      {
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getUsersSortableWordsLearnedInPeriod = ({
  appAuthToken,
  datetime,
  periodType,
  search,
  groupId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/usersSortableWordsLearnedInPeriod?${queryString.stringify(
      {
        datetime,
        periodType,
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getUsersSortableWordsRememberedInPeriod = ({
  appAuthToken,
  datetime,
  periodType,
  search,
  groupId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/usersSortableWordsRememberedInPeriod?${queryString.stringify(
      {
        datetime,
        periodType,
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getUsersSortableQuizTakenFirstTimeInPeriod = ({
  appAuthToken,
  datetime,
  periodType,
  search,
  groupId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/usersSortableQuizTakenFirstTimeInPeriod?${queryString.stringify(
      {
        datetime,
        periodType,
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getUsersSortableQuizTakenInPeriod = ({
  appAuthToken,
  datetime,
  periodType,
  search,
  groupId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/usersSortableQuizTakenInPeriod?${queryString.stringify(
      {
        datetime,
        periodType,
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getUsersLearningSummaryForFileExport = ({
  appAuthToken,
  fileType,
  groupId,
  targetAudienceId,
  from,
  to,
}) => {
  return httpGetDownloadFile(
    `${API_BASE_URL}/stats/userLearningSummaryFileExport?${queryString.stringify(
      {
        fileType,
        groupId,
        targetAudienceId,
        from,
        to,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};
