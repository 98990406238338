export const httpGet = async (uri, headers) => {
  try {
    let response = await fetch(uri, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      throw response;
    }

    let responseJson = await response.json();

    return responseJson;
  } catch (error) {
    throw error;
  }
};

export async function httpGetDownloadFile(
  url,
  headers,
  options = {}
) {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      ...options,
    });

    if (!response.ok) {
      throw response;
    }

    const responseBody = await response.blob();

    // Extract filename from header
    const filename = response.headers
      .get('content-disposition')
      .split(';')
      .find((n) => n.includes('filename='))
      .replace('filename=', '')
      .trim()
      .slice(1, -1);

    downloadBlob(responseBody, filename);
  } catch (error) {
    throw error;
  }
}

export const httpPost = async (uri, headers, jsonBody) => {
  try {
    let response = await fetch(uri, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(jsonBody),
    });

    if (!response.ok) {
      throw response;
    }

    if (response.status === 204) {
      return;
    }

    let responseJson = await response.json();

    return responseJson;
  } catch (error) {
    throw error;
  }
};

export const httpPut = async (uri, headers, jsonBody) => {
  try {
    let response = await fetch(uri, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(jsonBody),
    });

    if (!response.ok) {
      throw response;
    }

    if (response.status === 204) {
      return;
    }

    let responseJson = await response.json();

    return responseJson;
  } catch (error) {
    throw error;
  }
};

export const httpDelete = async (uri, headers, jsonBody) => {
  try {
    let response = await fetch(uri, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(jsonBody),
    });

    if (!response.ok) {
      throw response;
    }

    if (response.status === 204) {
      return;
    }

    let responseJson = await response.json();

    return responseJson;
  } catch (error) {
    throw error;
  }
};

export function upload({ method, url, appAuthToken, formData, onProgress }) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener(
      'progress',
      (evt) => {
        if (evt.lengthComputable) {
          let percentComplete = evt.loaded / evt.total;
          percentComplete = parseInt(percentComplete * 100);

          if (onProgress) {
            onProgress(percentComplete);
          }
        }
      },
      false
    );

    xhr.addEventListener('load', () => {
      if (xhr.status === 204) {
        resolve();
      } else if (xhr.status === 200 || xhr.status === 201) {
        const responseJson = JSON.parse(xhr.response);
        resolve(responseJson);
      } else {
        reject(xhr);
      }
    });
    xhr.addEventListener('error', () => reject(xhr));

    xhr.open(method, url);
    xhr.setRequestHeader('x-access-token', appAuthToken);
    xhr.send(formData);
  });
}

function downloadBlob(blob, filename = 'file') {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = filename;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
}
