import * as UserStatsApi from '../../api/userStats';

export const getUsersWordsLearned =
  ({ userIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserStatsApi.getUsersWordsLearned({
        appAuthToken,
        userIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get users words learned';
    }
  };

export const getUsersWordsRemembered =
  ({ userIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserStatsApi.getUsersWordsRemembered({
        appAuthToken,
        userIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get users words remembered';
    }
  };

export const getUsersQuizTakenFirstTime =
  ({ userIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserStatsApi.getUsersQuizTakenFirstTime({
        appAuthToken,
        userIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get users quiz taken (first time)');
    }
  };

export const getUsersQuizTaken =
  ({ userIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserStatsApi.getUsersQuizTaken({
        appAuthToken,
        userIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get users quiz taken');
    }
  };

export const getUsersScore =
  ({ userIds }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserStatsApi.getUsersScore({
        appAuthToken,
        userIds,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get users score');
    }
  };

export const getUsersSortableWordsLearned =
  ({ search, groupId, targetAudienceId, order, offset, limit }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserStatsApi.getUsersSortableWordsLearned({
        appAuthToken,
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get users with sortable words learned';
    }
  };

export const getUsersSortableWordsRemembered =
  ({ search, groupId, targetAudienceId, order, offset, limit }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserStatsApi.getUsersSortableWordsRemembered({
        appAuthToken,
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get users with sortable words remembered';
    }
  };

export const getUsersSortableQuizTakenFirstTime =
  ({ search, groupId, targetAudienceId, order, offset, limit }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserStatsApi.getUsersSortableQuizTakenFirstTime({
        appAuthToken,
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get users with sortable quiz taken (first time)');
    }
  };

export const getUsersSortableQuizTaken =
  ({ search, groupId, targetAudienceId, order, offset, limit }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserStatsApi.getUsersSortableQuizTaken({
        appAuthToken,
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get users with sortable quiz taken');
    }
  };

export const getUsersSortableScore =
  ({ search, groupId, targetAudienceId, order, offset, limit }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserStatsApi.getUsersSortableScore({
        appAuthToken,
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get users with sortable words remembered');
    }
  };

export const getUsersSortableWordsLearnedInPeriod =
  ({
    datetime,
    periodType,
    search,
    groupId,
    targetAudienceId,
    order,
    offset,
    limit,
  }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserStatsApi.getUsersSortableWordsLearnedInPeriod({
        appAuthToken,
        datetime,
        periodType,
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get users with sortable words learned in period';
    }
  };

export const getUsersSortableWordsRememberedInPeriod =
  ({
    datetime,
    periodType,
    search,
    groupId,
    targetAudienceId,
    order,
    offset,
    limit,
  }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response =
        await UserStatsApi.getUsersSortableWordsRememberedInPeriod({
          appAuthToken,
          datetime,
          periodType,
          search,
          groupId,
          targetAudienceId,
          order,
          offset,
          limit,
        });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get users with sortable words remembered in period';
    }
  };

export const getUsersSortableQuizTakenFirstTimeInPeriod =
  ({
    datetime,
    periodType,
    search,
    groupId,
    targetAudienceId,
    order,
    offset,
    limit,
  }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response =
        await UserStatsApi.getUsersSortableQuizTakenFirstTimeInPeriod({
          appAuthToken,
          datetime,
          periodType,
          search,
          groupId,
          targetAudienceId,
          order,
          offset,
          limit,
        });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error(
        'Cannot get users with sortable quiz taken (first time) in period'
      );
    }
  };

export const getUsersSortableQuizTakenInPeriod =
  ({
    datetime,
    periodType,
    search,
    groupId,
    targetAudienceId,
    order,
    offset,
    limit,
  }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserStatsApi.getUsersSortableQuizTakenInPeriod({
        appAuthToken,
        datetime,
        periodType,
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get users with sortable quiz taken in period');
    }
  };

export const getUsersLearningSummaryForFileExport =
  ({ fileType, groupId, targetAudienceId, from, to }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await UserStatsApi.getUsersLearningSummaryForFileExport({
        appAuthToken,
        fileType,
        groupId,
        targetAudienceId,
        from,
        to,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get users learning summary file export');
    }
  };
