import {
  call,
  put,
  all,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import * as Api from '../../api';

import * as srp from '../../utils/srp';

import {
  APP_INITIALIZED,
  // SET_USER,
  LOGIN,
  LOGIN_SUCCEED,
  LOGIN_FAILED,
  LOGOUT,
  LOGOUT_SUCCEED,
  LOGOUT_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCEED,
  CHANGE_PASSWORD_FAILED,
} from '../actions/types';

function getUser(state) {
  return state.user;
}

function getAppAuthToken(state) {
  return state.appAuthToken;
}

function* login({ username, password }) {
  try {
    const srpChallengeRequestData = srp.verifyStepOneClient();
    const {
      clientSecretEphemeral,
      clientPublicEphemeral,
    } = srpChallengeRequestData;

    const responseSrpChallenge = yield call(Api.requestSrpChallenge, {
      username,
      clientPublicEphemeral,
    });
    const {
      referenceId,
      serverPublicEphemeral,
      iterations,
      salt,
    } = responseSrpChallenge;

    const srpLoginRequestData = yield call(srp.verifyStepTwoClient, {
      username,
      password,
      iterations,
      salt,
      clientSecretEphemeral,
      serverPublicEphemeral,
    });

    const { clientSession, clientSessionProof } = srpLoginRequestData;

    const response = yield call(Api.loginSrp, {
      username,
      referenceId,
      clientSessionProof,
    });
    const { token: appAuthToken, user, serverSessionProof } = response;

    srp.clientVerifyServerProof({
      clientPublicEphemeral,
      clientSession,
      serverSessionProof,
    });

    localStorage.setItem('appAuthToken', appAuthToken);

    yield put({ type: LOGIN_SUCCEED, appAuthToken, user });
  } catch (e) {
    console.warn('login error:', e);
    if (e.message === 'Network request failed') {
      yield put({
        type: LOGIN_FAILED,
        error: { message: 'Service not available' },
      });
    } else if (e.status === 401) {
      yield put({
        type: LOGIN_FAILED,
        error: { message: 'Username or password is not valid' },
      });
    } else {
      // yield put({type: LOGIN_FAILED, message: e.message});
      yield put({ type: LOGIN_FAILED, error: e });
    }
  }

  yield put({ type: APP_INITIALIZED });
  console.info('App initialized');
}

function* watchLogin() {
  yield takeLatest(LOGIN, login);
}

function* logout() {
  try {
    localStorage.removeItem('appAuthToken');

    yield put({ type: LOGOUT_SUCCEED });
  } catch (e) {
    console.warn('logout error:', e);
  }
}

function* watchLogout() {
  yield takeLatest(LOGOUT, logout);
}

function* changePassword({ currentPassword, newPassword }) {
  try {
    const user = yield select(getUser);
    const { username } = user;

    const appAuthToken = yield select(getAppAuthToken);

    const srpChallengeRequestData = srp.verifyStepOneClient();
    const {
      clientSecretEphemeral,
      clientPublicEphemeral,
    } = srpChallengeRequestData;

    const responseSrpChallenge = yield call(Api.requestSrpChallenge, {
      username,
      clientPublicEphemeral,
    });
    const {
      referenceId,
      serverPublicEphemeral,
      iterations,
      salt,
    } = responseSrpChallenge;

    const srpLoginRequestData = yield call(srp.verifyStepTwoClient, {
      username,
      password: currentPassword,
      iterations,
      salt,
      clientSecretEphemeral,
      serverPublicEphemeral,
    });

    const { clientSessionProof } = srpLoginRequestData;

    const newCredential = yield call(srp.generateCredential, {
      username,
      password: newPassword,
    });

    yield call(Api.changeCredential, {
      appAuthToken,
      username,
      referenceId,
      clientSessionProof,
      newCredential,
    });

    yield put({ type: CHANGE_PASSWORD_SUCCEED });
  } catch (e) {
    console.warn('change password error:', e);
    if (e.message === 'Network request failed') {
      yield put({
        type: CHANGE_PASSWORD_FAILED,
        error: { message: 'Service not available' },
      });
    } else if (e.status === 401) {
      yield put({
        type: CHANGE_PASSWORD_FAILED,
        error: { message: 'Current password is not valid' },
      });
    } else {
      // yield put({type: LOGIN_FAILED, message: e.message});
      yield put({ type: CHANGE_PASSWORD_FAILED, error: e });
    }
  }
}

function* watchChangePassword() {
  yield takeLatest(CHANGE_PASSWORD, changePassword);
}

export default [watchLogin, watchLogout, watchChangePassword];
