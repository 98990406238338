import './App.scss';

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../redux/actions';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  // Link,
  Redirect,
} from 'react-router-dom';
import Loadable from 'react-loadable';

import Loading from '../../components/Loading';

const Login = Loadable({
  loader: () => import('../Login'),
  loading: Loading,
});
const Main = Loadable({
  loader: () => import('../Main'),
  loading: Loading,
});

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  componentDidMount() {
    this.props.initApp();
  }

  render() {
    if (!this.props.initialized) {
      return null;
    } else {
      const isAuthenticated = this.props.loginStatus.name === 'LOGGED_IN';
      return (
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              path="/"
              component={Main}
            />
            {/*<Route component={NotFound}/>*/}
          </Switch>
        </Router>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActionCreators, dispatch);
};

const mapStateToProps = (state) => {
  return {
    initialized: state.initialized,
    loginStatus: state.loginStatus,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
