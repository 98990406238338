import * as CmsContentTargetApi from '../../api/cms_content_target';

export const getContentTargets =
  ({ contentId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsContentTargetApi.getContentTargets({
        appAuthToken,
        contentId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get content targets');
    }
  };

export const addContentTarget =
  ({ contentId, targetAudienceId, targetTopicId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsContentTargetApi.addContentTarget({
        appAuthToken,
        contentId,
        targetAudienceId,
        targetTopicId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot add content target');
    }
  };

// export const updateContentTarget =
//   ({ contentId, targetAudienceId, targetTopicId, order }) =>
//   async (dispatch, getState) => {
//     const { appAuthToken } = getState();
//     try {
//       const response = await CmsContentTargetApi.updateContentTarget({
//         appAuthToken,
//         contentId,
//         targetAudienceId,
//         targetTopicId,
//         order,
//       });
//       return response;
//     } catch (error) {
//       console.error(error);
//       throw new Error('Cannot update content target');
//     }
//   };

export const removeContentTarget =
  ({ contentId, targetAudienceId, targetTopicId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsContentTargetApi.removeContentTarget({
        appAuthToken,
        contentId,
        targetAudienceId,
        targetTopicId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot remove content target');
    }
  };
