import { call, put, takeLatest } from 'redux-saga/effects';

import * as Api from '../../api';

import {
  INITIALIZE_APP,
  APP_INITIALIZED,
  // SET_USER,
  LOGIN_SUCCEED,
} from '../actions/types';

function* initApp() {
  const savedAppAuthToken = localStorage.getItem('appAuthToken');
  if (savedAppAuthToken) {
    try {
      const { token: appAuthToken, user } = yield call(
        Api.verifyTokenAndGetAdminUser,
        { appAuthToken: savedAppAuthToken }
      );

      // Save refreshed token
      localStorage.setItem('appAuthToken', appAuthToken);

      yield put({ type: LOGIN_SUCCEED, appAuthToken, user });
    } catch (e) {
      console.warn('init app error:', e);
    }
  }

  yield put({ type: APP_INITIALIZED });
  console.info('App initialized');
}

function* watchInitApp() {
  yield takeLatest(INITIALIZE_APP, initApp);
}

export default [watchInitApp];
