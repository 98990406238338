import queryString from 'query-string';

import { httpGet, httpPost, httpPut, httpDelete } from './common';

import { API_BASE_URL } from '../constants';

export const getUserSubscription = ({ appAuthToken, userId }) => {
  return httpGet(`${API_BASE_URL}/users/${userId}/subscription`, {
    'x-access-token': appAuthToken,
  });
};

export const getUserSubscriptionHistory = ({
  appAuthToken,
  userId,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/users/${userId}/subscription_history?${queryString.stringify(
      {
        offset,
        limit,
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const topupUserSubscription = ({
  appAuthToken,
  userId,
  subscriptionCode,
  duration,
  expiresAt,
}) => {
  return httpPost(
    `${API_BASE_URL}/topup_user_subscription`,
    {
      'x-access-token': appAuthToken,
    },
    { userId, subscriptionCode, duration, expiresAt }
  );
};

export const topupUsersInGroupSubscription = ({
  appAuthToken,
  groupId,
  subscriptionCode,
  duration,
  expiresAt,
}) => {
  return httpPost(
    `${API_BASE_URL}/topup_users_in_group_subscription`,
    {
      'x-access-token': appAuthToken,
    },
    { groupId, subscriptionCode, duration, expiresAt }
  );
};
