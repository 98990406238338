import * as CmsQuizQuestionApi from '../../api/cms_quiz_question';

export const getQuizQuestions =
  ({ learnGroupId, offset, limit, sort, sortOrder } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsQuizQuestionApi.getQuizQuestions({
        appAuthToken,
        learnGroupId,
        offset,
        limit,
        sort,
        sortOrder,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get quiz questions');
    }
  };

export const getQuizQuestion =
  ({ id, limitAdjacentIdToLearnGroupId } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsQuizQuestionApi.getQuizQuestion({
        appAuthToken,
        id,
        limitAdjacentIdToLearnGroupId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get quiz question');
    }
  };

export const createQuizQuestion =
  ({ formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsQuizQuestionApi.createQuizQuestion({
        appAuthToken,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Error creating quiz question');
    }
  };

export const updateQuizQuestion =
  ({ id, formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsQuizQuestionApi.updateQuizQuestion({
        appAuthToken,
        id,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Error updating quiz question');
    }
  };

export const removeQuizQuestion =
  ({ id }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsQuizQuestionApi.removeQuizQuestion({
        appAuthToken,
        id,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Error removing quiz question');
    }
  };

export const cloneQuizQuestion =
  ({ id, destinationLearnGroupId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsQuizQuestionApi.cloneQuizQuestion({
        appAuthToken,
        id,
        destinationLearnGroupId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Error cloning quiz question');
    }
  };
