import queryString from 'query-string';

import { httpGet, httpDelete, upload } from './common';

import { API_BASE_URL } from '../constants';

export const getTargetTopics = ({
  appAuthToken,
  search,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/target_topics?${queryString.stringify({
      search,
      offset,
      limit,
      sort,
      sortOrder,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getTargetTopic = ({ appAuthToken, targetTopicId }) => {
  return httpGet(`${API_BASE_URL}/target_topics/${targetTopicId}`, {
    'x-access-token': appAuthToken,
  });
};

export const createTargetTopic = ({
  appAuthToken,
  formData,
  onProgress,
}) => {
  return upload({
    method: 'POST',
    url: `${API_BASE_URL}/target_topics`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export const updateTargetTopic = ({
  appAuthToken,
  targetTopicId,
  formData,
  onProgress,
}) => {
  return upload({
    method: 'PUT',
    url: `${API_BASE_URL}/target_topics/${targetTopicId}`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export const removeTargetTopic = ({ appAuthToken, targetTopicId }) => {
  return httpDelete(`${API_BASE_URL}/target_topics/${targetTopicId}`, {
    'x-access-token': appAuthToken,
  });
};
