import * as TargetTopicApi from '../../api/target_topic';

export const getTargetTopics =
  ({ search, offset, limit, sort, sortOrder }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await TargetTopicApi.getTargetTopics({
        appAuthToken,
        search,
        offset,
        limit,
        sort,
        sortOrder,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get target topics');
    }
  };

export const getTargetTopic =
  ({ targetTopicId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await TargetTopicApi.getTargetTopic({
        appAuthToken,
        targetTopicId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot get target topic');
    }
  };

export const createTargetTopic =
  ({ formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await TargetTopicApi.createTargetTopic({
        appAuthToken,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot create target topic');
    }
  };

export const updateTargetTopic =
  ({ targetTopicId, formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await TargetTopicApi.updateTargetTopic({
        appAuthToken,
        targetTopicId,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot update target topic');
    }
  };

export const removeTargetTopic =
  ({ targetTopicId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await TargetTopicApi.removeTargetTopic({
        appAuthToken,
        targetTopicId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw new Error('Cannot remove target topic');
    }
  };
