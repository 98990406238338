// import queryString from 'query-string';

import { httpPut, httpPost, httpDelete } from './common';

import { API_BASE_URL } from '../constants';

export const setUserStaff = ({ appAuthToken, userId, isStaff }) => {
  return httpPut(
    `${API_BASE_URL}/users/${userId}/is_staff`,
    {
      'x-access-token': appAuthToken,
    },
    { isStaff }
  );
};

export const addUserToManagementUser = ({
  appAuthToken,
  managementUserId,
  userId,
}) => {
  return httpPost(
    `${API_BASE_URL}/users/${userId}/managementUsers`,
    {
      'x-access-token': appAuthToken,
    },
    { managementUserId }
  );
};

export const removeUserFromManagementUser = ({
  appAuthToken,
  managementUserId,
  userId,
}) => {
  return httpDelete(
    `${API_BASE_URL}/users/${userId}/managementUsers/${managementUserId}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};
