import * as AnnouncementApi from '../../api/announcement';

export const getAppAnnouncement = () => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await AnnouncementApi.getAppAnnouncement({
      appAuthToken,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw new Error('Cannot get app announcement');
  }
};

export const setAppAnnouncement = ({ formData, onProgress }) => async (
  dispatch,
  getState
) => {
  const { appAuthToken } = getState();
  try {
    const response = await AnnouncementApi.setAppAnnouncement({
      appAuthToken,
      formData,
      onProgress,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw new Error('Error setting app announcement');
  }
};

export const removeAppAnnouncement = () => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await AnnouncementApi.removeAppAnnouncement({
      appAuthToken,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw new Error('Error removing app announcement');
  }
};
