import queryString from 'query-string';

import { httpGet, httpPost, httpPut, httpDelete, upload } from './common';

import { API_BASE_URL } from '../constants';

export const getLearnGroups = ({
  appAuthToken,
  search,
  offset,
  limit,
  sort,
  sortOrder,
  notPublished,
  reviewPending,
  proofreadPending,
}) => {
  return httpGet(
    `${API_BASE_URL}/learnGroups?${queryString.stringify(
      {
        search,
        offset,
        limit,
        sort,
        sortOrder,
        notPublished,
        reviewPending,
        proofreadPending,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getLearnGroup = ({ appAuthToken, id }) => {
  return httpGet(`${API_BASE_URL}/learnGroups/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export const createLearnGroup = ({ appAuthToken, formData, onProgress }) => {
  return upload({
    method: 'POST',
    url: `${API_BASE_URL}/learnGroups`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export const updateLearnGroup = ({
  appAuthToken,
  id,
  formData,
  onProgress,
}) => {
  return upload({
    method: 'PUT',
    url: `${API_BASE_URL}/learnGroups/${id}`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export const deleteLearnGroup = ({ appAuthToken, id }) => {
  return httpDelete(`${API_BASE_URL}/learnGroups/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export const publishLearnGroup = ({
  appAuthToken,
  id,
  publish,
  scheduledPublishDatetime,
}) => {
  return httpPut(
    `${API_BASE_URL}/learnGroups/${id}/publish`,
    {
      'x-access-token': appAuthToken,
    },
    { publish, scheduledPublishDatetime }
  );
};

export const unpublishLearnGroup = ({
  appAuthToken,
  id,
  publish,
  scheduledPublishDatetime,
}) => {
  return httpPut(
    `${API_BASE_URL}/learnGroups/${id}/unpublish`,
    {
      'x-access-token': appAuthToken,
    },
    {}
  );
};

export const approveLearnGroup = ({
  appAuthToken,
  id,
  publish,
  scheduledPublishDatetime,
}) => {
  return httpPut(
    `${API_BASE_URL}/learnGroups/${id}/review`,
    {
      'x-access-token': appAuthToken,
    },
    { publish, scheduledPublishDatetime }
  );
};

export const cloneLearnGroupAndLearnData = ({
  appAuthToken,
  id,
  includeLearnData,
  includeQuizQuestions,
}) => {
  return httpPost(
    `${API_BASE_URL}/learnGroups/${id}/clone`,
    {
      'x-access-token': appAuthToken,
    },
    {
      includeLearnData,
      includeQuizQuestions,
    }
  );
};

export const proofreadLearnGroup = ({ appAuthToken, id }) => {
  return httpPut(`${API_BASE_URL}/learnGroups/${id}/proofread`, {
    'x-access-token': appAuthToken,
  });
};

export const getVocabs = ({
  appAuthToken,
  search,
  contentId,
  learnGroupId,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/learnData?${queryString.stringify({
      search,
      contentId,
      learnGroupId,
      offset,
      limit,
      sort,
      sortOrder,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};
export const getVocab = ({
  appAuthToken,
  id,
  limitAdjacentIdToLearnGroupId,
}) => {
  return httpGet(
    `${API_BASE_URL}/learnData/${id}?${queryString.stringify({
      limitAdjacentIdToLearnGroupId,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const updateVocab = ({ appAuthToken, id, formData, onProgress }) => {
  return upload({
    method: 'PUT',
    url: `${API_BASE_URL}/learnData/${id}`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export const cloneVocab = ({ appAuthToken, id, destinationLearnGroupId }) => {
  return httpPost(
    `${API_BASE_URL}/learnData/${id}/clone`,
    {
      'x-access-token': appAuthToken,
    },
    {
      destinationLearnGroupId,
    }
  );
};

export const checkLearnDataIdsExistence = ({ appAuthToken, ids }) => {
  return httpPost(
    `${API_BASE_URL}/learnDataIds/check_existence`,
    {
      'x-access-token': appAuthToken,
    },
    { ids }
  );
};

export const createLearnData = ({ appAuthToken, formData, onProgress }) => {
  return upload({
    method: 'POST',
    url: `${API_BASE_URL}/learnData`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export const uploadLearnDataBatch = ({
  appAuthToken,
  formData,
  onProgress,
}) => {
  return upload({
    method: 'POST',
    url: `${API_BASE_URL}/learnData_batch`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export const deleteVocab = ({ appAuthToken, id }) => {
  return httpDelete(`${API_BASE_URL}/learnData/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export const checkTagsExistence = ({ appAuthToken, tags }) => {
  return httpPost(
    `${API_BASE_URL}/tags/check_existence`,
    {
      'x-access-token': appAuthToken,
    },
    { tags }
  );
};

export const getTags = ({
  appAuthToken,
  search,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/tags?${queryString.stringify({
      search,
      offset,
      limit,
      sort,
      sortOrder,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const createTag = ({ appAuthToken, tag }) => {
  return httpPost(
    `${API_BASE_URL}/tags`,
    {
      'x-access-token': appAuthToken,
    },
    { tag }
  );
};

export const getTag = ({ appAuthToken, id }) => {
  return httpGet(`${API_BASE_URL}/tags/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export const updateTag = ({ appAuthToken, id, tag }) => {
  return httpPut(
    `${API_BASE_URL}/tags/${id}`,
    {
      'x-access-token': appAuthToken,
    },
    { tag }
  );
};

export const removeTag = ({ appAuthToken, id }) => {
  return httpDelete(`${API_BASE_URL}/tags/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export const getCategories = ({
  appAuthToken,
  search,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/categories?${queryString.stringify({
      search,
      offset,
      limit,
      sort,
      sortOrder,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const createCategory = ({ appAuthToken, category }) => {
  return httpPost(
    `${API_BASE_URL}/categories`,
    {
      'x-access-token': appAuthToken,
    },
    { category }
  );
};

export const getCategory = ({ appAuthToken, id }) => {
  return httpGet(`${API_BASE_URL}/categories/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export const updateCategory = ({ appAuthToken, id, category }) => {
  return httpPut(
    `${API_BASE_URL}/categories/${id}`,
    {
      'x-access-token': appAuthToken,
    },
    { category }
  );
};

export const removeCategory = ({ appAuthToken, id }) => {
  return httpDelete(`${API_BASE_URL}/categories/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export const getFeaturedTitleIds = ({ appAuthToken }) => {
  return httpGet(`${API_BASE_URL}/app_configs/featured_title_ids`, {
    'x-access-token': appAuthToken,
  });
};

export const setFeaturedTitleIds = ({ appAuthToken, config }) => {
  return httpPut(
    `${API_BASE_URL}/app_configs/featured_title_ids`,
    {
      'x-access-token': appAuthToken,
    },
    { config }
  );
};

export const getDiscoverScreenCategoryIds = ({ appAuthToken }) => {
  return httpGet(`${API_BASE_URL}/app_configs/discover_screen_category_ids`, {
    'x-access-token': appAuthToken,
  });
};

export const setDiscoverScreenCategoryIds = ({ appAuthToken, config }) => {
  return httpPut(
    `${API_BASE_URL}/app_configs/discover_screen_category_ids`,
    {
      'x-access-token': appAuthToken,
    },
    { config }
  );
};

export const getScheduledTasks = ({
  appAuthToken,
  offset,
  limit,
  sort,
  sortOrder,
}) => {
  return httpGet(
    `${API_BASE_URL}/scheduled_tasks?${queryString.stringify({
      offset,
      limit,
      sort,
      sortOrder,
    })}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getScheduledTask = ({ appAuthToken, id }) => {
  return httpGet(`${API_BASE_URL}/scheduled_tasks/${id}`, {
    'x-access-token': appAuthToken,
  });
};
