import * as SubscriptionApi from '../../api/subscription';

export const getUserSubscription = ({ userId }) => async (
  dispatch,
  getState
) => {
  const { appAuthToken } = getState();
  try {
    const response = await SubscriptionApi.getUserSubscription({
      appAuthToken,
      userId,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot get user subscription';
  }
};

export const getUserSubscriptionHistory = ({ userId, limit, offset }) => async (
  dispatch,
  getState
) => {
  const { appAuthToken } = getState();
  try {
    const response = await SubscriptionApi.getUserSubscriptionHistory({
      appAuthToken,
      userId,
      limit,
      offset,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot get user subscription history';
  }
};

export const topupUserSubscription = ({
  userId,
  subscriptionCode,
  duration,
  expiresAt,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await SubscriptionApi.topupUserSubscription({
      appAuthToken,
      userId,
      subscriptionCode,
      duration,
      expiresAt,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot top up user subscription';
  }
};

export const topupUsersInGroupSubscription = ({
  groupId,
  subscriptionCode,
  duration,
  expiresAt,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await SubscriptionApi.topupUsersInGroupSubscription({
      appAuthToken,
      groupId,
      subscriptionCode,
      duration,
      expiresAt,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot top up users in group subscription';
  }
};
