import * as AppActions from './app';
import * as AuthActions from './auth';
import * as ManagementUserActions from './managementUser';
import * as UserActions from './user';
import * as GroupActions from './group';
import * as SubgroupActions from './subgroup';
import * as RedemptionCodeActions from './redemptionCode';
import * as SubscriptionActions from './subscription';
import * as DashboardActions from './dashboard';
import * as LeaderboardActions from './leaderboard';
import * as StatsActions from './stats';
import * as TitleStatsActions from './titleStats';
import * as UserStatsActions from './userStats';
import * as SalesStatsActions from './salesStats';
import * as LearnGroupStats from './learn_group_stats';

import * as CmsActions from './cms';
import * as CmsContentActions from './cms_content';
import * as CmsContentTargetActions from './cms_content_target';
import * as CmsCourseActions from './cms_course';
import * as CmsQuizQuestionActions from './cms_quiz_question';
import * as CmsScheduleActions from './cms_schedule';
import * as TargetAudienceActions from './target_audience';
import * as TargetTopicActions from './target_topic';
import * as DiscoverScreenActions from './discover_screen';

import * as AnnouncementActions from './announcement';

export const ActionCreators = {
  ...AppActions,
  ...AuthActions,
  ...ManagementUserActions,
  ...UserActions,
  ...GroupActions,
  ...SubgroupActions,
  ...RedemptionCodeActions,
  ...SubscriptionActions,
  ...DashboardActions,
  ...LeaderboardActions,
  ...CmsActions,
  ...CmsContentActions,
  ...CmsContentTargetActions,
  ...CmsCourseActions,
  ...CmsQuizQuestionActions,
  ...CmsScheduleActions,
  ...TargetAudienceActions,
  ...TargetTopicActions,
  ...DiscoverScreenActions,
  ...AnnouncementActions,
  ...StatsActions,
  ...TitleStatsActions,
  ...UserStatsActions,
  ...SalesStatsActions,
  ...LearnGroupStats,
};
