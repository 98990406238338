import queryString from 'query-string';

import { httpGet, httpPost, httpPut, httpDelete } from './common';

import { API_BASE_URL } from '../constants';

export const getRedemptionCodes = ({
  appAuthToken,
  groupId,
  search,
  categoryIds,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/redemption_codes?${queryString.stringify(
      {
        groupId,
        search,
        order,
        categoryIds,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const createRedemptionCode = ({ appAuthToken, redemptionCode }) => {
  return httpPost(
    `${API_BASE_URL}/redemption_codes`,
    {
      'x-access-token': appAuthToken,
    },
    { redemptionCode }
  );
};

export const getRedemptionCode = ({ appAuthToken, id }) => {
  return httpGet(`${API_BASE_URL}/redemption_codes/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export const updateRedemptionCode = ({ appAuthToken, id, redemptionCode }) => {
  return httpPut(
    `${API_BASE_URL}/redemption_codes/${id}`,
    {
      'x-access-token': appAuthToken,
    },
    { redemptionCode }
  );
};

export const removeRedemptionCode = ({ appAuthToken, id }) => {
  return httpDelete(`${API_BASE_URL}/redemption_codes/${id}`, {
    'x-access-token': appAuthToken,
  });
};
