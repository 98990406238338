import * as CmsCourseApi from '../../api/cms_course';

export const getCourseMains =
  ({ groupId, search, offset, limit, sort, sortOrder } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.getCourseMains({
        appAuthToken,
        groupId,
        search,
        offset,
        limit,
        sort,
        sortOrder,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get course mains';
    }
  };

export const getCourseMain =
  ({ id } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.getCourseMain({ appAuthToken, id });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get course main';
    }
  };

export const createCourseMain =
  ({ formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.createCourseMain({
        appAuthToken,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error creating course main';
    }
  };

export const updateCourseMain =
  ({ id, formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.updateCourseMain({
        appAuthToken,
        id,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error updating course main';
    }
  };

export const removeCourseMain =
  ({ id }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.removeCourseMain({
        appAuthToken,
        id,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error removing course main';
    }
  };

export const getCourseTopics =
  ({
    courseMainId,
    search,
    targetAudienceId,
    offset,
    limit,
    sort,
    sortOrder,
  } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.getCourseTopics({
        appAuthToken,
        courseMainId,
        search,
        targetAudienceId,
        offset,
        limit,
        sort,
        sortOrder,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get course topics';
    }
  };

export const getCourseTopic =
  ({ id } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.getCourseTopic({ appAuthToken, id });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get course topic';
    }
  };

export const createCourseTopic =
  ({ formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.createCourseTopic({
        appAuthToken,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error creating course topic';
    }
  };

export const updateCourseTopic =
  ({ id, formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.updateCourseTopic({
        appAuthToken,
        id,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error updating course topic';
    }
  };

export const removeCourseTopic =
  ({ id }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.removeCourseTopic({
        appAuthToken,
        id,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error removing course topic';
    }
  };

export const getCourseSubtopics =
  ({ courseTopicId, search, offset, limit, sort, sortOrder } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.getCourseSubtopics({
        appAuthToken,
        courseTopicId,
        search,
        offset,
        limit,
        sort,
        sortOrder,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get course subtopics';
    }
  };

export const getCourseSubtopic =
  ({ id } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.getCourseSubtopic({
        appAuthToken,
        id,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get course subtopic';
    }
  };

export const createCourseSubtopic =
  ({ name, nameByLang, description, active, order, courseTopicId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.createCourseSubtopic({
        appAuthToken,
        name,
        nameByLang,
        description,
        active,
        order,
        courseTopicId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error creating course subtopic';
    }
  };

export const updateCourseSubtopic =
  ({ id, name, nameByLang, description, active, order, courseTopicId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.updateCourseSubtopic({
        appAuthToken,
        id,
        name,
        nameByLang,
        description,
        active,
        order,
        courseTopicId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error updating course subtopic';
    }
  };

export const removeCourseSubtopic =
  ({ id }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.removeCourseSubtopic({
        appAuthToken,
        id,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error removing course subtopic';
    }
  };

export const getCourses =
  ({ courseSubtopicId, search, offset, limit, sort, sortOrder } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.getCourses({
        appAuthToken,
        courseSubtopicId,
        search,
        offset,
        limit,
        sort,
        sortOrder,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get courses';
    }
  };

export const getCourse =
  ({ id } = {}) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.getCourse({ appAuthToken, id });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Cannot get course';
    }
  };

export const createCourse =
  ({ formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.createCourse({
        appAuthToken,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error creating course';
    }
  };

export const updateCourse =
  ({ id, formData, onProgress }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.updateCourse({
        appAuthToken,
        id,
        formData,
        onProgress,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error updating course';
    }
  };

export const removeCourse =
  ({ id }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.removeCourse({
        appAuthToken,
        id,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error removing course';
    }
  };

export const addContentToCourse =
  ({ courseId, contentId, order }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.addContentToCourse({
        appAuthToken,
        courseId,
        contentId,
        order,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error adding content to course';
    }
  };

export const updateCourseContent =
  ({ courseId, contentId, order }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.updateCourseContent({
        appAuthToken,
        courseId,
        contentId,
        order,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error updating content for coruse';
    }
  };

export const removeContentFromCourse =
  ({ courseId, contentId }) =>
  async (dispatch, getState) => {
    const { appAuthToken } = getState();
    try {
      const response = await CmsCourseApi.removeContentFromCourse({
        appAuthToken,
        courseId,
        contentId,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw 'Error removing content from course';
    }
  };
