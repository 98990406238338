import { httpPost } from './common';

import { API_BASE_URL } from '../constants';

export const getLearnGroupsByPublishDate = ({
  appAuthToken,
  publishStartDate,
  publishEndDate,
  sortOrder,
}) => {
  return httpPost(
    `${API_BASE_URL}/get_learn_groups_by_publish_date`,
    {
      'x-access-token': appAuthToken,
    },
    {
      publishStartDate,
      publishEndDate,
      sortOrder,
    }
  );
};

export const getScheduledPublishLearnGroupsByPublishDate = ({
  appAuthToken,
  publishStartDate,
  publishEndDate,
  sortOrder,
}) => {
  return httpPost(
    `${API_BASE_URL}/get_scheduled_publish_learn_groups_by_publish_date`,
    {
      'x-access-token': appAuthToken,
    },
    {
      publishStartDate,
      publishEndDate,
      sortOrder,
    }
  );
};

export const getContentsByContentUpdatedDate = ({
  appAuthToken,
  startDate,
  endDate,
  targetTopicId,
  targetAudienceId,
  sortOrder,
}) => {
  return httpPost(
    `${API_BASE_URL}/get_contents_by_content_updated_date`,
    {
      'x-access-token': appAuthToken,
    },
    {
      startDate,
      endDate,
      targetTopicId,
      targetAudienceId,
      sortOrder,
    }
  );
};

export const getScheduledSetContentUpdatedContentsByContentUpdatedDate = ({
  appAuthToken,
  startDate,
  endDate,
  targetTopicId,
  targetAudienceId,
  sortOrder,
}) => {
  return httpPost(
    `${API_BASE_URL}/get_scheduled_set_content_updated_contents_by_content_updated_date`,
    {
      'x-access-token': appAuthToken,
    },
    {
      startDate,
      endDate,
      targetTopicId,
      targetAudienceId,
      sortOrder,
    }
  );
};
