import { all, spawn } from 'redux-saga/effects';

import AppSagas from './app';
import AuthSagas from './auth';

const sagas = [...AppSagas, ...AuthSagas];

export default function* rootSaga() {
  yield all(sagas.map((saga) => spawn(saga)));
}
