// export const API_SERVER_URL = 'http://localhost:8080';

export const API_BASE_URL = '/api';

export const ROLE = {
  ADMIN: 'admin',
  CONTENT_EDITOR: 'content_editor',
  CONTENT_CREATOR: 'content_creator',
  GROUP_ADMIN: 'group_admin',
  GROUP_CONTENT_EDITOR: 'group_content_editor',
};

export const SUBSCRIPTION_TYPE = {
  FREE: {
    code: 1,
    name: 'FREE',
    tier: 0,
    iapProductIds: [],
  },
  FUN: {
    code: 2,
    name: 'FUN',
    tier: 1,
    iapProductIds: ['fun_1_month.sub'],
  },
  PREMIUM: {
    code: 3,
    name: 'PREMIUM',
    tier: 2,
    iapProductIds: ['premium_1_month.sub'],
  },
  ELITE: {
    code: 4,
    name: 'ELITE',
    tier: 3,
    iapProductIds: ['elite_1_month.sub'],
  },
};

export const GROUP_TYPES = {
  CORPERATE: 'CORPERATE',
  AFFILIATE: 'AFFILIATE',
  OTHER: 'OTHER',
};
