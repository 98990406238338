import queryString from 'query-string';

import { httpGet, httpPost } from './common';

import { API_BASE_URL } from '../constants';

export const getSalesCountSummary = ({
  appAuthToken,
  groupId,
  targetAudienceId,
  order,
}) => {
  return httpPost(
    `${API_BASE_URL}/stats/salesCountSummary`,
    {
      'x-access-token': appAuthToken,
    },
    {
      groupId,
      targetAudienceId,
      order,
    }
  );
};

export const getRecentSalesSummaryHistory = ({
  appAuthToken,
  periodType,
  periodCount,
  groupId,
  targetAudienceId,
}) => {
  return httpPost(
    `${API_BASE_URL}/stats/recentSalesSummaryHistory`,
    {
      'x-access-token': appAuthToken,
    },
    {
      periodType,
      periodCount,
      groupId,
      targetAudienceId,
    }
  );
};

export const getSalesUserCountSummary = ({
  appAuthToken,
  groupId,
  targetAudienceId,
}) => {
  return httpPost(
    `${API_BASE_URL}/stats/salesUserCountSummary`,
    {
      'x-access-token': appAuthToken,
    },
    {
      groupId,
      targetAudienceId,
    }
  );
};

export const getRecentSalesUserCountSummaryHistory = ({
  appAuthToken,
  periodType,
  periodCount,
  groupId,
  targetAudienceId,
}) => {
  return httpPost(
    `${API_BASE_URL}/stats/recentSalesUserCountSummaryHistory`,
    {
      'x-access-token': appAuthToken,
    },
    {
      periodType,
      periodCount,
      groupId,
      targetAudienceId,
    }
  );
};

export const getUsersSortableSalesSummaryInPeriod = ({
  appAuthToken,
  datetime,
  periodType,
  search,
  groupId,
  targetAudienceId,
  order,
  offset,
  limit,
}) => {
  return httpGet(
    `${API_BASE_URL}/stats/usersSortableSalesSummaryInPeriod?${queryString.stringify(
      {
        datetime,
        periodType,
        search,
        groupId,
        targetAudienceId,
        order,
        offset,
        limit,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getRecentSalesSummaryHistoryByUsers = ({
  appAuthToken,
  periodType,
  periodCount,
  userIds,
}) => {
  return httpPost(
    `${API_BASE_URL}/stats/recentSalesSummaryHistoryByUsers`,
    {
      'x-access-token': appAuthToken,
    },
    {
      periodType,
      periodCount,
      userIds,
    }
  );
};
