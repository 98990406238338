import * as Api from '../../api';

import * as srp from '../../utils/srp';

export const getManagementUsers = ({
  offset,
  limit,
  sort,
  sortOrder,
} = {}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.getManagementUsers({
      appAuthToken,
      offset,
      limit,
      sort,
      sortOrder,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot get management users';
  }
};

export const getManagementUser = ({ id }) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    const response = await Api.getManagementUser({ appAuthToken, id });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot get management user';
  }
};

export const createManagementUser = ({
  username,
  password,
  email,
  displayName,
  // profilePictureUrl,
  role,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  const { verifier, salt, iterations } = await srp.generateCredential({
    username,
    password,
  });
  try {
    const response = await Api.createManagementUser({
      appAuthToken,
      username,
      verifier,
      salt,
      iterations,
      email,
      displayName,
      role,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw 'Cannot create management user';
  }
};

export const updateManagementUser = ({
  id,
  username,
  role,
  email,
  displayName,
  // profilePictureUrl,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    await Api.updateManagementUser({
      appAuthToken,
      id,
      username,
      role,
      email,
      displayName,
    });
    return;
  } catch (error) {
    console.error(error);
    throw 'Cannot update management user';
  }
};

export const setActiveManagementUser = ({ id, active }) => async (
  dispatch,
  getState
) => {
  const { appAuthToken } = getState();
  try {
    await Api.setActiveManagementUser({
      appAuthToken,
      id,
      active,
    });
    return;
  } catch (error) {
    console.error(error);
    throw 'Cannot set management user active status';
  }
};

export const resetManagementUserPassword = ({ id, username, newPassword }) => async (
  dispatch,
  getState
) => {
  const newCredential = await srp.generateCredential({
    username,
    password: newPassword,
  });

  const { appAuthToken } = getState();
  try {
    await Api.resetManagementUserCredential({
      appAuthToken,
      id,
      newCredential,
    });
  } catch (error) {
    console.error(error);
    throw 'Cannot reset management user password';
  }
};

export const removeManagementUser = ({ id }) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    await Api.removeManagementUser({ appAuthToken, id });
  } catch (error) {
    console.error(error);
    throw 'Cannot remove management user';
  }
};

export const addGroupPermissionToManagementUser = ({
  managementUserId,
  groupIds,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    await Api.addGroupPermissionToManagementUser({
      appAuthToken,
      managementUserId,
      groupIds,
    });
  } catch (error) {
    console.error(error);
    throw 'Cannot add group permission to management user';
  }
};

export const removeGroupPermissionFromManagementUser = ({
  managementUserId,
  groupId,
}) => async (dispatch, getState) => {
  const { appAuthToken } = getState();
  try {
    await Api.removeGroupPermissionFromManagementUser({
      appAuthToken,
      managementUserId,
      groupId,
    });
  } catch (error) {
    console.error(error);
    throw 'Cannot remove group permission to management user';
  }
};
