import queryString from 'query-string';

import { httpGet, httpPost, httpPut, httpDelete, upload } from './common';

import { API_BASE_URL } from '../constants';

export const getContents = ({
  appAuthToken,
  search,
  categoryIds,
  targetTopicId,
  targetAudienceId,
  offset,
  limit,
  sort,
  sortOrder,
  includeUnpublished,
}) => {
  return httpGet(
    `${API_BASE_URL}/contents?${queryString.stringify(
      {
        search,
        categoryIds,
        targetTopicId,
        targetAudienceId,
        offset,
        limit,
        sort,
        sortOrder,
        includeUnpublished,
      },
      {
        arrayFormat: 'bracket',
      }
    )}`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export const getContent = ({ appAuthToken, id }) => {
  return httpGet(`${API_BASE_URL}/contents/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export const createContent = ({ appAuthToken, formData, onProgress }) => {
  return upload({
    method: 'POST',
    url: `${API_BASE_URL}/contents`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export const updateContent = ({ appAuthToken, id, formData, onProgress }) => {
  return upload({
    method: 'PUT',
    url: `${API_BASE_URL}/contents/${id}`,
    appAuthToken,
    formData,
    onProgress,
  });
};

export const deleteContent = ({ appAuthToken, id }) => {
  return httpDelete(`${API_BASE_URL}/contents/${id}`, {
    'x-access-token': appAuthToken,
  });
};

export function cloneContent({
  appAuthToken,
  id,
  includeLearnGroups,
  includeLearnDataInLearnGroup,
  includeQuizQuestionsInLearnGroup,
}) {
  return httpPost(
    `${API_BASE_URL}/contents/${id}/clone`,
    {
      'x-access-token': appAuthToken,
    },
    {
      includeLearnGroups,
      includeLearnDataInLearnGroup,
      includeQuizQuestionsInLearnGroup,
    }
  );
}

export const setContentUpdatedAt = ({
  appAuthToken,
  id,
  scheduled,
  scheduledSetUpdatedAtDatetime,
}) => {
  return httpPut(
    `${API_BASE_URL}/contents/${id}/content_updated_at`,
    {
      'x-access-token': appAuthToken,
    },
    {
      scheduled,
      scheduledSetUpdatedAtDatetime,
    }
  );
};

export const cancelScheduledSetContentUpdatedAt = ({ appAuthToken, id }) => {
  return httpPost(
    `${API_BASE_URL}/contents/${id}/cancel_scheduled_content_updated_at`,
    {
      'x-access-token': appAuthToken,
    }
  );
};

export function addLearnGroupToContent({
  appAuthToken,
  contentId,
  learnGroupId,
  order,
}) {
  return httpPost(
    `${API_BASE_URL}/contents/${contentId}/learnGroups`,
    {
      'x-access-token': appAuthToken,
    },
    { learnGroupId, order }
  );
}

export function updateContentLearnGroup({
  appAuthToken,
  contentId,
  learnGroupId,
  order,
}) {
  return httpPut(
    `${API_BASE_URL}/contents/${contentId}/learnGroups/${learnGroupId}`,
    {
      'x-access-token': appAuthToken,
    },
    { order }
  );
}

export function removeLearnGroupFromContent({
  appAuthToken,
  contentId,
  learnGroupId,
}) {
  return httpDelete(
    `${API_BASE_URL}/contents/${contentId}/learnGroups/${learnGroupId}`,
    {
      'x-access-token': appAuthToken,
    }
  );
}
